import { Draggable as DragIcon } from "@carbon/icons-react";
import { Box, Typography, useTheme } from "@mui/material";

const RankItem = ({ text, disabled }: { text: string; disabled: boolean }) => {
  const { palette } = useTheme();
  return (
    <Box
      border={`1px solid ${palette.divider}`}
      borderRadius={2}
      p={2.5}
      display="flex"
      gap={1}
      sx={{ cursor: "grab" }}
      mb={2.5}
    >
      <DragIcon size={24} color={palette.text.disabled} />
      <Typography color={disabled ? palette.text.disabled : "primary"}>{text}</Typography>
    </Box>
  );
};

export default RankItem;
