import {
  PropsWithChildren,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useWsmPlugin } from "@/hooks/useWsmPlugin";
import { WSMRoute, WSMRouteDetail } from "@WahooFitness/wsm-native/dist/esm/types/route";
import { useLocation, useParams } from "react-router";
import { distanceBetweenPoints } from "@WahooFitness/unit-convertr-ts";
import { RouteSentStatus } from "./RouteSentStatus";
import { LocalEntityType } from "@WahooFitness/wsm-native/dist/esm/types/local_entity_type";
import {
  WSMCloudSyncState,
  WSMCloudSyncType,
} from "@WahooFitness/wsm-native/dist/esm/types/cloud_sync_event";
import useCloudSyncListener from "@/hooks/useCloudSyncListener";
import {
  SortDirection,
  SortDirectionOption,
  SortKey,
  SortOption,
  SortParams,
} from "@/components/FilterBar/SortParams";
import { t } from "@lingui/macro";
import {
  defaultRouteFilterParams,
  FilterParams,
  MAX_DISTANCE_FILTER,
  MAX_ELEVATION_FILTER,
} from "@/components/FilterBar/FilterParams";
import { useSnackbarContext } from "@WahooFitness/wahoo-offline-mfe";
import useGeolocation from "@/hooks/useGeolocation";
import mockCuratedRoutes from "./../webMocks/routeMockData/curatedRouteMockData";

export type ExtendedWSMRoute = WSMRoute & {
  distanceToStart?: number;
};

type RouteContextType = {
  routeList: ExtendedWSMRoute[];
  routeJson?: WSMRouteDetail;
  routeDetails?: ExtendedWSMRoute;
  sortParams: SortParams;
  filterParams: FilterParams;
  sortOptions: Array<SortOption>;
  sortDirections: Array<SortDirectionOption>;
  selectedProviderId?: string;
  selectedRouteSentState?: RouteSentStatus;
  updateSort: (value: SortParams) => void;
  setSortKey: (key: string) => void;
  setSortDirection: (direction: string) => void;
  updateFilter: (value: FilterParams) => void;
  clearFilterParam: (value: keyof FilterParams) => void;
  clearAllFilters: () => void;
  filtersApplied: boolean;
  sendRouteToConnectedDevices: (
    providerType: number,
    providerId: string,
    fitnessAppId: number
  ) => Promise<void>;
  sendRouteToConnectedDevicesWithSnackbar: (
    providerType: number,
    providerId: string,
    fitnessAppId: number
  ) => Promise<void>;
  reverseRoute: () => void;
  isRouteReversed: boolean;
  refreshRouteList: () => void;
  pendingPublicRouteIds: number[];
  pendingRemovedPublicRouteIds: number[];
  shareRoutePublicly: (route: WSMRoute, expiresAt: Date) => void;
  stopSharingRoutePublicly: (route: WSMRoute) => void;
  startPublicRouteSync: () => void;
  publicRoutesAreSynced: boolean;
  publicRoutes: WSMRoute[];
  curatedRoutes: WSMRoute[];
  importPublicRoute: (route: WSMRoute) => Promise<boolean>;
  editRoute: (routeToken: string, edits: Partial<WSMRoute>) => Promise<boolean>;
  routesSyncError?: string;
  publicRoutesSyncError?: string;
  routeFetchError?: string;
  routeIsLoading: boolean;
  selectRouteDrawerOpen: boolean;
  openSelectRouteDrawer: (event: React.MouseEvent, token: string) => void;
  closeSelectRouteDrawer: () => void;
};

export const RoutesContext = createContext<RouteContextType | undefined>(undefined);

export const RoutesProvider = ({ children }: PropsWithChildren) => {
  const params = useParams();
  const { state } = useLocation();
  const { sensorManagerPlugin } = useWsmPlugin();
  const { enqueueSnackbar } = useSnackbarContext();
  const [wsmRouteList, setWsmRouteList] = useState<WSMRoute[]>([]);
  const [routeJson, setRouteJson] = useState<WSMRouteDetail>();
  const [sortParams, setSortParams] = useState<SortParams>({
    sortKey: SortKey.DATE,
    sortDir: SortDirection.DESC,
  });
  const [selectedProviderId, setSelectedProviderId] = useState<string | undefined>();
  const [selectedRouteSentState, setSelectedRouteSentState] = useState<
    RouteSentStatus | undefined
  >();
  const [isRouteReversed, setIsRouteReversed] = useState(false);

  const [filterParams, setFilterParams] = useState<FilterParams>(defaultRouteFilterParams);
  const [tempToken, setTempToken] = useState<string | undefined>(undefined);
  const [publicRoutes, setPublicRoutes] = useState<WSMRoute[]>([]);
  const [curatedRoutes, setCuratedRoutes] = useState<WSMRoute[]>([]);

  const selectedRouteToken = useMemo(() => tempToken ?? params.token, [params.token, tempToken]);

  const selectedRoute = useRef<WSMRouteDetail | undefined>();
  const updateSort = useCallback((newSortParams: SortParams) => {
    setSortParams(newSortParams);
  }, []);

  function updateFilter(newFilterParams: Partial<FilterParams>) {
    setFilterParams((currentFilter) => ({ ...currentFilter, ...newFilterParams }));
  }

  function clearFilterParam(filterKey: keyof FilterParams) {
    setFilterParams((currentFilter) => ({ ...currentFilter, [filterKey]: undefined }));
  }

  const setSortKey = useCallback(
    (key: string) => {
      updateSort({
        sortDir: sortParams?.sortDir || SortDirection.DESC,
        sortKey: key as SortKey,
      });
    },
    [sortParams?.sortDir, updateSort]
  );

  const setSortDirection = useCallback(
    (direction: string) => {
      updateSort({
        sortDir: direction as SortDirection,
        sortKey: sortParams?.sortKey || SortKey.DATE,
      });
    },
    [sortParams?.sortKey, updateSort]
  );

  function clearAllFilters() {
    setFilterParams(defaultRouteFilterParams);
  }
  // TODO: TSS-1198 remove that function once we always show the drawer
  async function sendRouteToConnectedDevices(
    providerType: number,
    providerId: string,
    fitnessAppId: number
  ) {
    if (providerId === selectedProviderId) {
      return;
    }
    setSelectedProviderId(providerId);
    setSelectedRouteSentState(RouteSentStatus.PENDING);
    const result = await sensorManagerPlugin?.selectRoute({
      providerType: providerType,
      providerId: providerId,
      fitnessAppId: fitnessAppId,
      reverse: isRouteReversed,
    });

    setSelectedRouteSentState(result?.completed ? RouteSentStatus.SUCCESS : RouteSentStatus.FAILED);
    fetchUserRoutes();
    setTimeout(() => {
      setSelectedProviderId(undefined);
      setSelectedRouteSentState(undefined);
    }, 5000);
  }

  async function sendRouteToConnectedDevicesWithSnackbar(
    providerType: number,
    providerId: string,
    fitnessAppId: number
  ) {
    enqueueSnackbar({
      message: t`Sending route to ELEMNT...`,
      severity: "info",
    });
    const result = await sensorManagerPlugin?.selectRoute({
      providerType: providerType,
      providerId: providerId,
      fitnessAppId: fitnessAppId,
      reverse: isRouteReversed,
    });
    if (result?.completed) {
      enqueueSnackbar({
        message: t`Success!`,
        severity: "success",
      });
    }
    if (!result?.completed) {
      enqueueSnackbar({
        message: t`Failed to send to ELEMNT`,
        severity: "error",
      });
    }
    fetchUserRoutes();
  }

  useEffect(() => {
    async function fetchRouteJson(token: string) {
      const responseJson = (await sensorManagerPlugin?.getRouteDetails({ token }))?.routeDetail;
      if (responseJson) {
        setRouteJson(responseJson);
        selectedRoute.current = responseJson;
      }
    }
    if (selectedRouteToken) {
      fetchRouteJson(selectedRouteToken);
    } else {
      setIsRouteReversed(false);
      setRouteJson(undefined);
    }
  }, [selectedRouteToken, sensorManagerPlugin]);

  const [routeFetchError, setRouteFetchError] = useState<string | undefined>(undefined);

  const fetchCuratedRoutes = useCallback(async () => {
    // TODO TSS-1305 wire up the real cloud content once it's available
    setCuratedRoutes(mockCuratedRoutes);
  }, []);

  const fetchUserRoutes = useCallback(async () => {
    const wsmRoutes = (await sensorManagerPlugin?.getUserRouteList({}))?.routes;
    if (wsmRoutes) {
      setWsmRouteList(wsmRoutes);
      setRouteFetchError(undefined);
    } else {
      setRouteFetchError("Failed to fetch routes");
    }
  }, [sensorManagerPlugin]);

  const routeIsLoading = useMemo(() => {
    return !wsmRouteList && !routeFetchError;
  }, [routeFetchError, wsmRouteList]);

  useEffect(() => {
    fetchUserRoutes();
  }, [fetchUserRoutes]);

  useEffect(() => {
    fetchCuratedRoutes();
  }, [fetchCuratedRoutes]);

  const [routesSyncError, setRoutesSyncError] = useState<string | undefined>(undefined);
  const refreshRouteList = useCallback(async () => {
    const completed = (await sensorManagerPlugin?.startRouteSync())?.completed;
    if (!completed) {
      setRoutesSyncError("Failed to refresh routes");
    } else {
      setRoutesSyncError(undefined);
    }
  }, [sensorManagerPlugin]);

  const { addListener: addCloudSyncListener, removeListener: removeCloudSyncListener } =
    useCloudSyncListener();

  useEffect(() => {
    const callback = (type: WSMCloudSyncType, state: WSMCloudSyncState) => {
      if (type === WSMCloudSyncType.Route && state === WSMCloudSyncState.Ready) {
        fetchUserRoutes();
      }
    };
    addCloudSyncListener(callback);
    return () => {
      removeCloudSyncListener(callback);
    };
  }, [addCloudSyncListener, fetchUserRoutes, removeCloudSyncListener]);

  useEffect(() => {
    const listener = sensorManagerPlugin?.addListener("cloudEntityChanged", (event) => {
      if (event.entityType === LocalEntityType.Route) {
        fetchUserRoutes();
      }
    });
    return () => {
      (async () => (await listener)?.remove())();
    };
  }, [fetchUserRoutes, sensorManagerPlugin]);

  const { cachedPosition: currentLocation } = useGeolocation();

  const wsmRouteListWithDistance: ExtendedWSMRoute[] = useMemo(() => {
    return (state?.public ? publicRoutes : wsmRouteList).map((route) => {
      if (currentLocation && route.start_lat && route.start_lng) {
        return {
          distanceToStart: distanceBetweenPoints(
            currentLocation.latitude,
            isRouteReversed ? routeJson?.records[0].lat_deg ?? 0 : route.start_lat ?? 0,
            currentLocation.longitude,
            isRouteReversed ? routeJson?.records[0].lon_deg ?? 0 : route.start_lng
          ),
          ...route,
        };
      }
      return route;
    });
  }, [currentLocation, isRouteReversed, publicRoutes, routeJson?.records, state, wsmRouteList]);

  const routeDetails = useMemo(
    () => wsmRouteListWithDistance.find((route) => route.token === selectedRouteToken),
    [selectedRouteToken, wsmRouteListWithDistance]
  );

  const sortOptions = useMemo(
    () => [
      { id: SortKey.DATE, label: t`Date` },
      { id: SortKey.ALPHA, label: t`A-Z` },
      { id: SortKey.DISTANCE, label: t`Distance` },
      { id: SortKey.PROXIMITY, label: t`Proximity` },
    ],
    []
  );

  const sortDirections = useMemo(
    () => [
      { id: SortDirection.ASC, label: t`Ascending` },
      { id: SortDirection.DESC, label: t`Descending` },
    ],
    []
  );

  const filteredSortedRouteList = useMemo(() => {
    let tempRouteList = [...wsmRouteListWithDistance];
    if (filterParams?.search && filterParams.search.length) {
      tempRouteList = tempRouteList.filter((route) =>
        route.name.toLocaleLowerCase().includes(filterParams.search!.toLocaleLowerCase())
      );
    }
    if (filterParams.distance?.min_m && filterParams.distance?.min_m > 0) {
      tempRouteList = tempRouteList.filter(
        (route) => !route.distance || route.distance > filterParams.distance!.min_m
      );
    }
    if (filterParams.distance?.max_m && filterParams.distance?.max_m < MAX_DISTANCE_FILTER) {
      tempRouteList = tempRouteList.filter(
        (route) => !route.distance || route.distance < filterParams.distance!.max_m
      );
    }
    if (filterParams.elevation_gain?.min_m && filterParams.elevation_gain?.min_m > 0) {
      tempRouteList = tempRouteList.filter(
        (route) => !route.ascent || route.ascent > filterParams.elevation_gain!.min_m
      );
    }
    if (
      filterParams.elevation_gain?.max_m &&
      filterParams.elevation_gain?.max_m < MAX_ELEVATION_FILTER
    ) {
      tempRouteList = tempRouteList.filter(
        (route) => !route.ascent || route.ascent < filterParams.elevation_gain!.max_m
      );
    }
    const familyFilterIsDefaultAny =
      filterParams.family?.toString() === defaultRouteFilterParams.family.toString();
    if (filterParams.family && filterParams.family.length && !familyFilterIsDefaultAny) {
      tempRouteList = tempRouteList.filter(
        (route) =>
          route.workout_type_family_id !== undefined &&
          filterParams.family?.includes(route.workout_type_family_id)
      );
    }
    if (sortParams.sortKey && sortParams.sortDir) {
      switch (sortParams.sortKey) {
        case SortKey.ALPHA:
          tempRouteList = tempRouteList.sort((a, b) => {
            if (a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase()) {
              return -1;
            }
            if (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()) {
              return 1;
            }
            return 0;
          });
          break;
        case SortKey.DATE:
          tempRouteList = tempRouteList.sort(
            (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
          );
          break;
        case SortKey.DISTANCE:
          tempRouteList = tempRouteList.sort((a, b) => (a.distance || 0) - (b.distance || 0));
          break;
        case SortKey.PROXIMITY:
          tempRouteList = tempRouteList.sort(
            (a, b) => (a.distanceToStart || 0) - (b.distanceToStart || 0)
          );
          break;
      }
      if (sortParams.sortDir === SortDirection.DESC) {
        tempRouteList = tempRouteList.reverse();
      }
    }
    return tempRouteList;
  }, [
    wsmRouteListWithDistance,
    filterParams.search,
    filterParams.distance,
    filterParams.elevation_gain,
    filterParams.family,
    sortParams.sortKey,
    sortParams.sortDir,
  ]);

  const filtersApplied = useMemo(
    () => JSON.stringify(filterParams) !== JSON.stringify(defaultRouteFilterParams),
    [filterParams]
  );

  const reverseRoute = useCallback(() => {
    if (selectedRoute.current) {
      if (isRouteReversed) {
        setRouteJson(selectedRoute.current);
        setIsRouteReversed(false);
      } else {
        const originalRecords = selectedRoute.current.records.map((record) => ({ ...record }));
        const reversedRecords = originalRecords.slice().reverse();
        const tempDistances = reversedRecords.map((record, index) => {
          if (index < reversedRecords.length - 1) {
            return Number(record.dist_m) - Number(reversedRecords[index + 1]?.dist_m);
          }
        });
        reversedRecords.forEach((record, index) => {
          if (index === 0) {
            record.dist_m = 0;
          } else {
            record.dist_m =
              Number(reversedRecords[index - 1].dist_m) + Number(tempDistances[index - 1]);
          }
        });
        const reversedRoute = { ...selectedRoute.current, records: reversedRecords };
        setRouteJson(reversedRoute);
        setIsRouteReversed(true);
      }
    }
  }, [isRouteReversed]);

  const [pendingPublicRouteIds, setPendingPublicRouteIds] = useState<number[]>([]);
  const [pendingRemovedPublicRouteIds, setPendingRemovedPublicRouteIds] = useState<number[]>([]);

  const setExpiresAt = useCallback(
    async (token: string, expiresAt: Date | null) => {
      return !!(
        await sensorManagerPlugin?.editLocalEntity({
          entityType: LocalEntityType.Route,
          token,
          edits: {
            expires_at: expiresAt?.toISOString() || null,
          },
        })
      )?.completed;
    },
    [sensorManagerPlugin]
  );

  const shareRoutePublicly = useCallback(
    async (route: WSMRoute, expiresAt: Date) => {
      setPendingPublicRouteIds((current) => [...current, route.id]);
      if (pendingRemovedPublicRouteIds.includes(route.id)) {
        setPendingRemovedPublicRouteIds((current) => current.filter((id) => id !== route.id));
      }
      await setExpiresAt(route.token || "", expiresAt);
    },
    [pendingRemovedPublicRouteIds, setExpiresAt]
  );

  const stopSharingRoutePublicly = useCallback(
    async (route: WSMRoute) => {
      setPendingRemovedPublicRouteIds((current) => [...current, route.id]);
      if (pendingPublicRouteIds.includes(route.id)) {
        setPendingPublicRouteIds((current) => current.filter((id) => id !== route.id));
      }
      setExpiresAt(route.token || "", null);
    },
    [pendingPublicRouteIds, setExpiresAt]
  );

  useEffect(() => {
    for (const routeId of pendingPublicRouteIds) {
      const route = wsmRouteList.find((route) => route.id === routeId);
      if (route?.expires_at && new Date(route.expires_at) > new Date()) {
        setPendingPublicRouteIds((current) => current.filter((id) => id !== routeId));
      }
    }
  }, [pendingPublicRouteIds, wsmRouteList]);

  useEffect(() => {
    for (const routeId of pendingRemovedPublicRouteIds) {
      const route = wsmRouteList.find((route) => route.id === routeId);
      if (!route?.expires_at || new Date(route.expires_at) <= new Date()) {
        setPendingRemovedPublicRouteIds((current) => current.filter((id) => id !== routeId));
      }
    }
  }, [pendingRemovedPublicRouteIds, wsmRouteList]);

  const [publicRoutesAreSyncing, setPublicRoutesAreSyncing] = useState(false);
  const [publicRoutesAreHttpSynced, setPublicRoutesAreHttpSynced] = useState(false);
  const startPublicRouteSync = useCallback(() => {
    setPublicRoutesAreSyncing(true);
  }, []);

  const fetchPublicRoutes = useCallback(async () => {
    const results = await sensorManagerPlugin?.getPublicRoutes();
    setPublicRoutes(results?.routes ?? []);
  }, [sensorManagerPlugin]);

  const [publicRoutesSyncError, setPublicRoutesSyncError] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (publicRoutesAreSyncing) {
      const callback = (type: WSMCloudSyncType, state: WSMCloudSyncState) => {
        if (type === WSMCloudSyncType.PublicRoute && state === WSMCloudSyncState.Ready) {
          setPublicRoutesAreSyncing(false);
          fetchPublicRoutes();
          setPublicRoutesAreHttpSynced(true);
        }
      };
      (async () => {
        addCloudSyncListener(callback);
        const completed = (await sensorManagerPlugin?.startPublicRouteSync())?.completed;
        if (!completed) {
          setPublicRoutesSyncError("Failed to sync public routes");
          setPublicRoutesAreSyncing(false);
        }
        setPublicRoutesAreHttpSynced(false);
      })();

      return () => {
        removeCloudSyncListener(callback);
      };
    }
  }, [
    addCloudSyncListener,
    fetchPublicRoutes,
    publicRoutesAreSyncing,
    removeCloudSyncListener,
    sensorManagerPlugin,
  ]);

  const importPublicRoute = useCallback(
    async (route: WSMRoute) => {
      return !!(await sensorManagerPlugin?.importPublicRoute({ routeClId: route.id }))?.completed;
    },
    [sensorManagerPlugin]
  );

  const editRoute = useCallback(
    async (routeToken: string, edits: Partial<WSMRoute>) => {
      return !!(
        await sensorManagerPlugin?.editLocalEntity({
          entityType: LocalEntityType.Route,
          token: routeToken,
          edits,
        })
      )?.completed;
    },
    [sensorManagerPlugin]
  );

  const [selectRouteDrawerOpen, setSelectRouteDrawerOpen] = useState(false);

  const openSelectRouteDrawer = useCallback((event: React.MouseEvent, token: string) => {
    event.stopPropagation();
    setTempToken(token);
    setSelectRouteDrawerOpen(true);
  }, []);

  const closeSelectRouteDrawer = useCallback(() => {
    setTempToken(undefined);
    setSelectRouteDrawerOpen(false);
  }, []);

  return (
    <RoutesContext.Provider
      value={{
        routeList: filteredSortedRouteList,
        routeJson,
        routeDetails,
        sortParams,
        filterParams,
        selectedProviderId,
        selectedRouteSentState,
        updateSort,
        setSortKey,
        setSortDirection,
        updateFilter,
        clearFilterParam,
        clearAllFilters,
        filtersApplied,
        sendRouteToConnectedDevices,
        sendRouteToConnectedDevicesWithSnackbar,
        reverseRoute,
        isRouteReversed,
        refreshRouteList,
        shareRoutePublicly,
        stopSharingRoutePublicly,
        pendingPublicRouteIds,
        pendingRemovedPublicRouteIds,
        startPublicRouteSync,
        publicRoutesAreSynced: publicRoutesAreHttpSynced,
        publicRoutes,
        curatedRoutes,
        importPublicRoute,
        editRoute,
        routesSyncError,
        publicRoutesSyncError,
        routeFetchError,
        routeIsLoading,
        sortOptions,
        sortDirections,
        selectRouteDrawerOpen,
        openSelectRouteDrawer,
        closeSelectRouteDrawer,
      }}
    >
      {children}
    </RoutesContext.Provider>
  );
};
