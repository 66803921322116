import { Box, InputAdornment, TextField } from "@mui/material";
import { FocusEventHandler, useEffect, useState } from "react";
import { t } from "@lingui/macro";
import { ImperialMetricDisplayPreference } from "@WahooFitness/cloud-client-types";
import { useWeight } from "@/hooks/useWeight";
import { truncateToTwoDecimalPlaces } from "@/services/truncateNumbers";

type Props = {
  value: number;
  unitPreference: ImperialMetricDisplayPreference;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
};

const WeightInput = ({ value, unitPreference, onBlur }: Props) => {
  const [isWeightValid, setIsWeightValid] = useState(false);
  const { errorMessage, handleChange, currentValue, handleBlur, setCurrentValue } = useWeight({
    initialWeight: value,
    unitPreference,
    onBlur,
    isWeightValid,
    setIsWeightValid,
  });

  useEffect(() => {
    if (value && value !== 0) {
      setCurrentValue(truncateToTwoDecimalPlaces(value));
    }
  }, [setCurrentValue, value]);

  return (
    <Box display="flex" flexDirection="column">
      <TextField
        label={t`Weight`}
        value={currentValue}
        variant="outlined"
        type="number"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {unitPreference === ImperialMetricDisplayPreference.metric ? t`kg` : t`lbs`}
            </InputAdornment>
          ),
        }}
        inputProps={{ inputMode: "decimal", "data-testid": "weightInput" }}
        onBlur={handleBlur}
        onChange={handleChange}
        error={!!errorMessage}
        helperText={errorMessage}
      />
    </Box>
  );
};

export default WeightInput;
