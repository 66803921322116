import { t } from "@lingui/macro";
import { FourDPQuiz } from "@WahooFitness/cloud-client-ts";
import { FourDPQuizResponseType, FourDPQuizResultsType } from "@WahooFitness/cloud-client-types";
import {
  useCloudContext,
  useConfigContext,
  useOfflineSWR,
  useSnackbarContext,
} from "@WahooFitness/wahoo-offline-mfe";
import { useCallback } from "react";

function useFourDPQuizClient() {
  const { getCloudClient } = useCloudContext();
  const { wahooToken } = useConfigContext();
  const { enqueueSnackbar } = useSnackbarContext();

  const fourDPQuizClient = getCloudClient(FourDPQuiz);
  const {
    data: quizData,
    isLoading: quizLoading,
    error: quizError,
  } = useOfflineSWR(["fourDPquizQuestions"], () => fourDPQuizClient.get(wahooToken));

  const getQuizResults = useCallback(
    async (quizResponse: FourDPQuizResponseType): Promise<FourDPQuizResultsType | null> => {
      try {
        const results = await fourDPQuizClient.post(wahooToken, quizResponse);
        return results;
      } catch (err) {
        enqueueSnackbar({
          message: t`There was a problem submitting your questionnaire. Check your network and try again.`,
          severity: "error",
        });
        return null;
      }
    },
    [enqueueSnackbar, fourDPQuizClient, wahooToken]
  );

  return {
    quizData,
    quizLoading,
    quizError,
    getQuizResults,
  };
}

export default useFourDPQuizClient;
