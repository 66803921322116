import { useHeaderContext } from "@/hooks/useHeaderContext";
import { Plural, t, Trans } from "@lingui/macro";
import {
  BlueSwitch,
  ChevronRightIcon,
  LiveTrackIcon,
  MenuList,
  MenuListItemType,
  MenuListItemVariant,
} from "@WahooFitness/redesignr";
import { useUserContext } from "@WahooFitness/wahoo-offline-mfe";
import { useEffect, useMemo } from "react";
import { useLiveTrack } from "./useLiveTrack";
import { alpha, Avatar, Box, Button, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router";

const LiveTrack = () => {
  const { palette } = useTheme();
  const { setNavHeader } = useHeaderContext();
  const navigate = useNavigate();
  const { currentUser } = useUserContext();
  const {
    liveTrackContacts,
    toggleLiveTrackEnabled,
    toggleOptOutEnabled,
    handleShareLink,
    liveTrackStatus,
  } = useLiveTrack();

  useEffect(() => {
    setNavHeader({ title: t`Live Track` });
  }, [setNavHeader]);

  const liveTrackHeader = useMemo(
    () => (
      <Box width="100%" px={2} display="flex" flexDirection="column" alignItems="center">
        <Avatar sx={{ width: "76px", height: "76px", bgcolor: alpha(palette.info.main, 0.1) }}>
          <LiveTrackIcon color="info" sx={{ transform: "scale(2.33)" }} />
        </Avatar>
        <Typography
          color={palette.text.primary}
          variant="prose-base-bold"
          mt={2}
          px={2}
          textAlign="center"
        >
          <Trans>Live Track</Trans>
        </Typography>
        <Typography color={palette.text.primary} variant="prose-sm" mt={2} textAlign="center">
          <Trans>
            With Live Track, your friends and family can follow your real-time location while you
            record activities on your ELEMNT bike computer.
          </Trans>
        </Typography>
        <Typography color={palette.text.primary} variant="prose-sm" mt={2} textAlign="center">
          <Trans>
            Important: your phone must be turned on with Bluetooth enabled and in range of your
            device to maintain a connection.
          </Trans>
        </Typography>
      </Box>
    ),
    [palette.info.main, palette.text.primary]
  );

  const liveTrackConfigItems = useMemo<MenuListItemType[]>(
    () => [
      {
        id: "live-track-header",
        content: "",
        secondaryContent: liveTrackHeader,
        variant: MenuListItemVariant.NoAction,
      },
      {
        id: "live-track-enabled",
        content: t`Live Track`,
        variant: MenuListItemVariant.Action,
        actionComponent: (
          <BlueSwitch
            checked={currentUser?.share_setting.livetrack_enabled}
            data-testid="live_track_enabled"
          />
        ),
        action: toggleLiveTrackEnabled,
      },
      {
        id: "live-track-contacts",
        content: t`Auto-share`,
        variant: MenuListItemVariant.Action,
        actionComponent: (
          <>
            <Plural value={liveTrackContacts?.length ?? 0} one="# contact" other="# contacts" />{" "}
            <ChevronRightIcon sx={{ marginLeft: 1 }} />
          </>
        ),
        action: () => navigate("/live-track/contacts"),
      },
      {
        id: "live-track-links",
        content: t`Public link`,
        secondaryContent: (
          <Typography variant="prose-sm" color={liveTrackStatus.color ?? palette.text.secondary}>
            {liveTrackStatus.message}
          </Typography>
        ),
        variant: MenuListItemVariant.Action,
        actionComponent: (
          <Box p={1}>
            <Button variant="outlined">
              <Trans>Share link</Trans>
            </Button>
          </Box>
        ),
        action: handleShareLink,
        hidden: !currentUser?.share_setting.livetrack_enabled,
      },
    ],
    [
      currentUser?.share_setting.livetrack_enabled,
      handleShareLink,
      liveTrackContacts?.length,
      liveTrackHeader,
      liveTrackStatus.color,
      liveTrackStatus.message,
      navigate,
      palette.text.secondary,
      toggleLiveTrackEnabled,
    ]
  );

  const nearbyConfigItems = useMemo<MenuListItemType[]>(
    () => [
      {
        id: "live-track-opt-out",
        content: t`Make me visible to everyone`,
        secondaryContent: t`When enabled, your first name, last name initial and location will be shown to other ELEMNT riders.`,
        variant: MenuListItemVariant.Action,
        actionComponent: (
          <BlueSwitch
            checked={!currentUser?.share_setting.livetrack_opt_out}
            data-testid="live_track_enabled"
          />
        ),
        action: toggleOptOutEnabled,
        hidden: !currentUser?.share_setting.livetrack_enabled,
      },
    ],
    [
      currentUser?.share_setting.livetrack_enabled,
      currentUser?.share_setting.livetrack_opt_out,
      toggleOptOutEnabled,
    ]
  );

  return (
    <Box m={2} display="flex" flexDirection="column" gap={2}>
      <Box>
        <MenuList disableGutters listItems={liveTrackConfigItems} />
        <Box mx={2}>
          <Typography variant="prose-sm">
            <Trans>Shared links will automatically expire after 12 hours of inactivity.</Trans>
          </Typography>
        </Box>
      </Box>
      <MenuList disableGutters listItems={nearbyConfigItems} />
    </Box>
  );
};

export { LiveTrack };
