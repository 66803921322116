import { Box, debounce } from "@mui/material";
import { DatePicker, DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { isAfter, isBefore, startOfDay, sub } from "date-fns";
import { useCallback, useEffect, useMemo, useState } from "react";
import { t } from "@lingui/macro";
import { de, enGB, enUS, es, fr, it, ja, ko, nl, pt, th, zhCN, zhTW } from "date-fns/locale";
import { useConfigContext } from "@WahooFitness/wahoo-offline-mfe";

type Props = {
  value: Date | null;
  onChange?: (value: Date | null) => void;
  textFieldOnlyPicker?: boolean;
};

const DateOfBirthInput = ({ value, onChange, textFieldOnlyPicker }: Props) => {
  const [birthDateValid, setBirthDateValid] = useState<boolean>(true);
  const [helperText, setHelperText] = useState<string | undefined>(undefined);
  const [birthDate, setBirthDate] = useState<Date | null>(value);

  const { locale } = useConfigContext();
  const localeMap: { [key: string]: Locale } = {
    en: navigator.languages[0] === "en-GB" ? enGB : enUS,
    "zh-Hans": zhCN,
    "zh-Hant": zhTW,
    nl: nl,
    fr: fr,
    de: de,
    it: it,
    ja: ja,
    ko: ko,
    pt: pt,
    es: es,
    th: th,
    pseudo: enUS, //Assuming pseudo uses en-US as a placeholder
  };

  useEffect(() => {
    setBirthDate(value);
  }, [value]);

  const minBirthDate = useMemo(() => {
    return new Date(1900, 0, 1);
  }, []);

  const maxBirthDate = useMemo(() => {
    const today = new Date();
    const eighteenYearsAgo = startOfDay(sub(today, { years: 18 }));
    return eighteenYearsAgo;
  }, []);

  const debouncedOnChange = useMemo(() => onChange && debounce(onChange, 1000), [onChange]);

  const handleChange = useCallback(
    (value: Date | null) => {
      if (!value || isNaN(value.getTime())) {
        setBirthDateValid(false);
        setHelperText(t`Please enter a valid birth date`);
      } else if (isAfter(value, maxBirthDate)) {
        setBirthDateValid(false);
        setHelperText(t`Users must be at least 18 years of age`);
      } else if (isBefore(value, minBirthDate)) {
        setBirthDateValid(false);
        setHelperText(t`Please enter a valid birth date`);
      } else {
        setBirthDate(value);
        setBirthDateValid(true);
        setHelperText(undefined);
        debouncedOnChange && debouncedOnChange(value);
      }
    },
    [debouncedOnChange, maxBirthDate, minBirthDate]
  );

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={localeMap[locale]}>
        {textFieldOnlyPicker ? (
          <DesktopDatePicker
            disableOpenPicker
            label={t`Date`}
            value={birthDate}
            minDate={minBirthDate}
            maxDate={maxBirthDate}
            onChange={handleChange}
            slotProps={{
              textField: {
                variant: "outlined",
                error: !birthDateValid,
                helperText,
                inputProps: {
                  "data-testid": "dateOfBirthInputTextField",
                },
              },
            }}
          />
        ) : (
          <DatePicker
            disableFuture
            label={t`Date of birth`}
            openTo="day"
            views={["year", "month", "day"]}
            value={birthDate}
            minDate={minBirthDate}
            maxDate={maxBirthDate}
            onChange={handleChange}
            slotProps={{
              textField: {
                variant: "outlined",
                error: !birthDateValid,
                helperText,
                inputProps: {
                  "data-testid": "dateOfBirthInput",
                },
              },
            }}
          />
        )}
      </LocalizationProvider>
    </Box>
  );
};

export default DateOfBirthInput;
