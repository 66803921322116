import { Dispatch, SetStateAction } from "react";
import { Box, Checkbox, FormControlLabel, Typography, useTheme } from "@mui/material";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import { StrictModeDroppable } from "@/components/WorkoutProfiles/WorkoutPages/StrictModeDroppable";
import { Responses } from "../useFourDPQuiz";
import useRanking from "./useRanking";
import { Trans } from "@lingui/macro";
import RankItem from "./RankItem";
import { AnswerListPossibility } from "@WahooFitness/cloud-client-types/FourDPQuizType";

interface Props {
  initialVal?: Array<string>;
  options: Array<AnswerListPossibility>;
  skipText?: string;
  setResponses: Dispatch<SetStateAction<Responses | undefined>>;
  questionId: number;
}

const Ranking = ({ initialVal, options, setResponses, questionId, skipText }: Props) => {
  const { onDragEnd, orderedOptions, skipChecked, handleSkipToggle } = useRanking({
    initialVal,
    options,
    setResponses,
    questionId,
  });

  const { palette } = useTheme();

  return (
    <Box
      height="100%"
      width="100%"
      maxWidth="sm"
      py={2}
      display="flex"
      flexDirection="column"
      gap={2.5}
    >
      <Typography color={skipChecked ? palette.text.disabled : "primary"}>
        <Trans>Drag and drop items in preferred order</Trans>
      </Typography>
      <DragDropContext onDragEnd={onDragEnd}>
        <StrictModeDroppable droppableId={`4dp-ranking-question-${questionId}`}>
          {(provided) => (
            <Box
              display="flex"
              flexDirection="column"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {orderedOptions.map((option, index) => (
                <Draggable
                  key={option.key}
                  draggableId={option.key}
                  index={index}
                  isDragDisabled={skipChecked}
                >
                  {(provided) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.dragHandleProps}
                      {...provided.draggableProps}
                    >
                      <RankItem text={option.text.title} disabled={skipChecked} />
                    </Box>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </StrictModeDroppable>
      </DragDropContext>
      {skipText && (
        <Box mx={2}>
          <FormControlLabel
            control={<Checkbox checked={skipChecked} onChange={handleSkipToggle} />}
            label={skipText}
          />
        </Box>
      )}
    </Box>
  );
};

export default Ranking;
