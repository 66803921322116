import { User } from "@carbon/icons-react";
import { Avatar, Box, IconButton, Skeleton, Typography } from "@mui/material";
import { UserType } from "@WahooFitness/cloud-client-types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getKnightTitle } from "@/services/getKnightTitle";
import {
  useUserContext,
  useNativeMessagingContext,
  postChangeAvatar,
  usePubSubContext,
  PubSubSubscription,
} from "@WahooFitness/wahoo-offline-mfe";

const UserCard = ({ user }: { user: UserType }) => {
  const [avatarUpdating, setAvatarUpdating] = useState(false);
  const [_avatarUpdateSubscription, setAvatarUpdateSubscription] = useState<
    PubSubSubscription | undefined
  >(undefined);

  const { mutateUser } = useUserContext();
  const { subscribeToUserUpdates } = usePubSubContext();
  const { addRefreshListener, removeRefreshListener } = useNativeMessagingContext();

  const knightTitle = useMemo(() => getKnightTitle(user), [user]);

  const endAvatarUpdate = useCallback(() => {
    setAvatarUpdating(false);
    removeRefreshListener("cancelAvatarUpdate");
    setAvatarUpdateSubscription((previousValue) => {
      if (previousValue) {
        previousValue.unsubscribe();
      }
      return undefined;
    });
  }, [removeRefreshListener]);

  const refreshUserData = useCallback(
    (_event: string) => {
      mutateUser();
    },
    [mutateUser]
  );

  // This effect removes listeners and takes down the loading skeleton when the avatar url changes.
  useEffect(() => {
    if (user.avatar?.url) {
      endAvatarUpdate();
    }
  }, [endAvatarUpdate, user.avatar?.url]);

  const handleImageRequest = useCallback(() => {
    addRefreshListener("cancelAvatarUpdate", endAvatarUpdate);
    const subscription = subscribeToUserUpdates(user.id, refreshUserData);
    setAvatarUpdateSubscription(subscription);
    postChangeAvatar();
    setAvatarUpdating(true);
  }, [addRefreshListener, endAvatarUpdate, subscribeToUserUpdates, user.id, refreshUserData]);

  return (
    <Box display="flex" flexDirection="column" textAlign={"center"} gap={2} alignItems="center">
      {avatarUpdating ? (
        <Skeleton height={44} width={44} variant="circular" />
      ) : (
        <IconButton onClick={handleImageRequest} size="large" data-testid="profile.avatar">
          <Avatar
            src={user.avatar?.url}
            imgProps={{ height: 48, width: 48 }}
            sx={{ color: "text.primary", bgcolor: "secondary.main", height: 48, width: 48 }}
          >
            <User size={20} />
          </Avatar>
        </IconButton>
      )}
      <Box display="flex" flexDirection="column" width="100%" flexShrink={1}>
        <Typography variant="prose-lg-medium" color="text.primary" sx={{ wordBreak: "break-all" }}>
          {`${knightTitle} ${user.first} ${user.last}`}
        </Typography>
        <Typography variant="prose-base" color="text.secondary" sx={{ wordBreak: "break-all" }}>
          {user.email}
        </Typography>
      </Box>
    </Box>
  );
};

export default UserCard;
