import { t } from "@lingui/macro";
import StrengthArrow from "@/assets/StrengthArrow.png";
import WeaknessArrow from "@/assets/WeaknessArrow.png";
import { FitnessProfiles, UserProfiles } from "@WahooFitness/cloud-client-ts";
import { useCallback, useEffect, useMemo } from "react";
import {
  useConfigContext,
  useOfflineSWR,
  useCloudContext,
  useNativeMessagingContext,
  useDrawerContext,
} from "@WahooFitness/wahoo-offline-mfe";

import useFlaggedFeatures from "@/hooks/useFlaggedFeatures";
import { FlaggedFeature } from "@/hooks/types/FlaggedFeature";
import { useNavigate } from "react-router";

import useLocalizedFitnessData from "./useLocalizedFitnessData";
import { WahooWorkoutTypeFamily } from "@WahooFitness/cloud-client-types";
import HowWeCalculateDrawer from "./HowWeCalculateDrawer";

const useFitnessProfile = () => {
  const { wahooToken } = useConfigContext();
  const { getCloudClient } = useCloudContext();
  const { addRefreshListener, removeRefreshListener } = useNativeMessagingContext();

  const { setDrawer } = useDrawerContext();
  const { checkIsFeatureEnabled } = useFlaggedFeatures();
  const navigate = useNavigate();

  const { getRiderTypeById, getRiderTypeStrengthById, getRiderTypeWeaknessById } =
    useLocalizedFitnessData();
  const fitnessProfilesClient = useMemo(() => getCloudClient(FitnessProfiles), [getCloudClient]);

  const {
    data: userFitnessProfilesData,
    isLoading: userFitnessProfilesIsLoading,
    error: userFitnessProfilesError,
    mutate: mutateFitnessProfile,
  } = useOfflineSWR(["userFitnessProfiles", wahooToken], ([_key, token]) =>
    fitnessProfilesClient.get(token)
  );

  useEffect(() => {
    const listenerKey = "reloadFitnessProfiles";
    addRefreshListener(listenerKey, mutateFitnessProfile);
    return () => removeRefreshListener(listenerKey);
  }, [addRefreshListener, mutateFitnessProfile, removeRefreshListener]);

  const userProfilesClient = useMemo(() => getCloudClient(UserProfiles), [getCloudClient]);
  const { data: userProfilesData } = useOfflineSWR(
    ["trainingPreferences", wahooToken],
    ([_key, token]) => userProfilesClient.get(token)
  );

  const hasCyclingTrainingPreference = useMemo(
    () => userProfilesData?.workout_type_family_ids.includes(WahooWorkoutTypeFamily.Cycling),
    [userProfilesData?.workout_type_family_ids]
  );

  const cyclingFitnessProfilesData = useMemo(
    () =>
      userFitnessProfilesData?.find(
        (profile) => profile.workout_type_family_id === WahooWorkoutTypeFamily.Cycling
      ),
    [userFitnessProfilesData]
  );

  const riderTypeData = useMemo(
    () => getRiderTypeById(cyclingFitnessProfilesData?.fitness_type_id),
    [cyclingFitnessProfilesData?.fitness_type_id, getRiderTypeById]
  );

  const riderTypeStrength = useMemo(
    () => getRiderTypeStrengthById(cyclingFitnessProfilesData?.fitness_strength_id),
    [cyclingFitnessProfilesData?.fitness_strength_id, getRiderTypeStrengthById]
  );
  const riderTypeWeakness = useMemo(
    () => getRiderTypeWeaknessById(cyclingFitnessProfilesData?.fitness_weakness_id),
    [cyclingFitnessProfilesData?.fitness_weakness_id, getRiderTypeWeaknessById]
  );
  const riderStrength = useMemo(() => {
    if (riderTypeStrength) {
      return {
        name: riderTypeStrength?.name,
        description: riderTypeStrength?.description,
        icon: StrengthArrow,
      };
    }
  }, [riderTypeStrength]);
  const riderWeakness = useMemo(() => {
    if (riderTypeWeakness) {
      return {
        name: riderTypeWeakness?.name,
        description: riderTypeWeakness?.description,
        icon: WeaknessArrow,
      };
    }
  }, [riderTypeWeakness]);

  const riderTypeIcon = useMemo(() => riderTypeData?.icon, [riderTypeData?.icon]);
  const riderTypeIconFilled = useMemo(() => riderTypeData?.iconFilled, [riderTypeData?.iconFilled]);
  const riderTypeName = useMemo(() => riderTypeData?.name, [riderTypeData?.name]);
  const hasNoFitnessProfile = useMemo(
    () => !cyclingFitnessProfilesData?.fitness_type_id,
    [cyclingFitnessProfilesData?.fitness_type_id]
  );

  const profileDescription = useMemo(() => {
    if (riderTypeData?.description) return riderTypeData.description;
    if (hasNoFitnessProfile) {
      return t`Your athlete profile gives you a complete view of your strengths and weaknesses, revealing
          what you're capable of now and giving insights to how you'll perform in a any
          challenge.`;
    }
    return "";
  }, [hasNoFitnessProfile, riderTypeData?.description]);

  const handleQuestionnaireClick = () => {
    navigate("/4dp-quiz");
  };

  const showQuestionnaireCard = useMemo(
    () => checkIsFeatureEnabled(FlaggedFeature.FOURDPQuizMinProfile),
    [checkIsFeatureEnabled]
  );

  const openInfoDrawer = useCallback(() => {
    setDrawer({
      open: true,
      title: t`How we calculate?`,
      hidePuller: true,
      children: <HowWeCalculateDrawer />,
      elevation: 4,
    });
  }, [setDrawer]);

  // TODO: make more dynamic once the quiz is hooked up
  const isRiderTypeEstimate = useMemo(() => {
    return true;
  }, []);

  return {
    userFitnessProfilesIsLoading,
    userFitnessProfilesError,
    mutateFitnessProfile,
    cyclingFitnessProfilesData,
    riderTypeName,
    riderTypeIcon,
    riderTypeIconFilled,
    hasNoFitnessProfile,
    profileDescription,
    userFitnessProfilesData,
    handleQuestionnaireClick,
    showQuestionnaireCard,
    riderStrength,
    riderWeakness,
    hasCyclingTrainingPreference,
    openInfoDrawer,
    isRiderTypeEstimate,
  };
};

export default useFitnessProfile;
