import { t } from "@lingui/macro";
import { PropsWithChildren, useCallback, useMemo } from "react";
import {
  MenuList,
  MenuListItemType,
  MenuListItemVariant,
  SlidingDrawer,
} from "@WahooFitness/redesignr";
import { Box } from "@mui/material";
import { useRoutesContext } from "@/hooks/useRoutesContext";
import ElementImage from "@/assets/ElemntRoam2.png";
import { postStartRouteMessage, useConfigContext } from "@WahooFitness/wahoo-offline-mfe";
import useSubscription, { SubscriptionState } from "@/hooks/useSubscription";
import usePaywall from "@/hooks/usePaywall";

interface SelectRouteDrawerProps {
  open: boolean;
  handleClose: () => void;
}

function SelectRouteDrawer({ open, handleClose }: PropsWithChildren<SelectRouteDrawerProps>) {
  const { globalBottomPadding, platform } = useConfigContext();
  const { subscriptionState } = useSubscription();
  const { showPaywall } = usePaywall();
  const { sendRouteToConnectedDevicesWithSnackbar, routeDetails, isRouteReversed } =
    useRoutesContext();
  const {
    provider_id: providerId,
    route_provider_type: providerType,
    fitness_app_id: fitnessAppId,
  } = routeDetails || {};

  const handleSendRouteToDevice = useCallback(() => {
    if (providerType !== undefined && providerId && fitnessAppId) {
      sendRouteToConnectedDevicesWithSnackbar(providerType, providerId, fitnessAppId);
      // timeout is not really necessary, but it makes the drawer close animation smoother/nicer
      setTimeout(() => {
        handleClose();
      }, 300);
    }
  }, [
    fitnessAppId,
    handleClose,
    providerId,
    providerType,
    sendRouteToConnectedDevicesWithSnackbar,
  ]);

  const handleLaunchIndoorWorkout = useCallback(() => {
    if (subscriptionState !== SubscriptionState.Active) {
      showPaywall({ handleClose });
      return;
    }
    if (routeDetails?.id && routeDetails?.token) {
      postStartRouteMessage(routeDetails?.id, routeDetails?.token, undefined, isRouteReversed);
      handleClose();
    }
  }, [
    handleClose,
    routeDetails?.id,
    routeDetails?.token,
    showPaywall,
    subscriptionState,
    isRouteReversed,
  ]);

  const listItems: MenuListItemType[] = useMemo(
    () => [
      {
        id: "sendToElement",
        content: t`Send to ELEMNT`,
        secondaryContent: t`Load this route onto your ELEMNT`,
        variant: MenuListItemVariant.Action,
        action: handleSendRouteToDevice,
        actionComponent: <img src={ElementImage} alt="Action Icon" style={{ height: 55 }} />,
      },
      {
        id: "indoorWorkout",
        content: t`Launch indoors`,
        secondaryContent: t`Simulate this route in Wahoo app`,
        variant: MenuListItemVariant.Action,
        action: handleLaunchIndoorWorkout,
      },
    ],
    [handleLaunchIndoorWorkout, handleSendRouteToDevice]
  );

  if (!routeDetails) {
    return null;
  }

  return (
    <SlidingDrawer
      platform={platform}
      globalBottomPadding={globalBottomPadding}
      open={open}
      title={routeDetails.name}
      handleOpen={() => {}}
      handleClose={handleClose}
      hidePuller
    >
      <Box py={2} px={1}>
        {listItems.map((item) => (
          <MenuList key={item.id} listItems={[item]} />
        ))}
      </Box>
    </SlidingDrawer>
  );
}

export default SelectRouteDrawer;
