import { Profile } from "@WahooFitness/cloud-client-types";
import { EnvironmentConfigType as OfflineMfeEnvironmentConfigType } from "@WahooFitness/wahoo-offline-mfe";

type EnvironmentConfigType = OfflineMfeEnvironmentConfigType & {
  mapboxToken: string;
};

type CloudConfigType = {
  wahooCloudRestUrl: string;
  fayeServerUrl: string;
  wahooMyAccountUrl: string;
  wahooLinksUrl: string;
  outOfTheBoxUrl: string;
};

const defaultRemoteConfig = {
  CURATED_ROUTES_MIN_PROFILE: Profile.alpha,
  UPDATED_PAYWALL_MIN_PROFILE: Profile.alpha,
  ELEMNT_CONFIG_MIN_PROFILE: Profile.std,
  ELEMNT_MULTISPORT_PAGE_MIN_PROFILE: Profile.alpha,
  ELEMNT_PEDAL_PAGE_MIN_PROFILE: Profile.alpha,
  ELEMNT_PLANNED_WORKOUTS_MIN_PROFILE: Profile.alpha,
  LOCATION_SHARING_MIN_PROFILE: Profile.alpha,
  PACEZONES_MIN_PROFILE: Profile.std,
  STRAVA_LIVE_SEGMENTS_MIN_PROFILE: Profile.std,
  SUGGESTED_WORKOUTS_MIN_PROFILE: Profile.std,
  SUMMARY_AUTO_SHARE_MIN_PROFILE: Profile.std,
  WORKOUT_ALERTS_MIN_PROFILE: Profile.std,
  ELEMNT_ALERT_COUNT_WARNING_ENABLED: false,
  LIVE_TRACK_CONFIG_MIN_PROFILE: Profile.alpha,
};

const stagingEnvironment: EnvironmentConfigType = {
  cloudConfig: {
    wahooCloudRestUrl: "https://staging.wahooligan.com",
    fayeServerUrl: "https://mb.staging.wahooligan.com/faye",
    wahooMyAccountUrl: "https://accounts.staging.wahooligan.com",
    wahooLinksUrl: "https://link.staging.wahoofitness.com",
    outOfTheBoxUrl: "https://setup.staging.wahooligan.com",
  },
  firebaseConfig: {
    apiKey: "AIzaSyBrIC63Wthaaw8L5J3KgBOg2V9Bp1UnQpE",
    authDomain: "athlete-profile-402518.firebaseapp.com",
    projectId: "athlete-profile-402518",
    storageBucket: "athlete-profile-402518.appspot.com",
    messagingSenderId: "778040863322",
    appId: "1:778040863322:web:fc105e064e4ac22cca4663",
    measurementId: "G-JB4HG9VDZZ",
  },
  fallbackAppId: 1387,
  componentId: 3,
  mapboxToken: import.meta.env.VITE_MAPBOX_TOKEN,
  bugsnagApiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
  appVersion: SETTINGS_MFE_VERSION,
  defaultRemoteConfig,
};

const productionEnvironment: EnvironmentConfigType = {
  cloudConfig: {
    wahooCloudRestUrl: "https://www.wahooligan.com",
    fayeServerUrl: "https://mb.wahooligan.com/faye",
    wahooMyAccountUrl: "https://accounts.wahooligan.com",
    wahooLinksUrl: "https://link.wahoofitness.com",
    outOfTheBoxUrl: "https://setup.wahoofitness.com",
  },
  firebaseConfig: {
    apiKey: "AIzaSyBrIC63Wthaaw8L5J3KgBOg2V9Bp1UnQpE",
    authDomain: "athlete-profile-402518.firebaseapp.com",
    projectId: "athlete-profile-402518",
    storageBucket: "athlete-profile-402518.appspot.com",
    messagingSenderId: "778040863322",
    appId: "1:778040863322:web:ebe47cdd54a74ab9ca4663",
    measurementId: "G-5Q6M2R3VSK",
  },
  fallbackAppId: 1387,
  componentId: 3,
  mapboxToken: import.meta.env.VITE_MAPBOX_TOKEN,
  bugsnagApiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
  appVersion: SETTINGS_MFE_VERSION,
  defaultRemoteConfig,
};

const getEnvironmentConfig = (
  environment: "staging" | "production" | undefined
): EnvironmentConfigType => {
  if (environment === "production") {
    return productionEnvironment;
  }
  return stagingEnvironment;
};

export { getEnvironmentConfig };
export type { CloudConfigType };
