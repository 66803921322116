import { publish } from "@ionic/portals";
import { Box, Paper, Typography } from "@mui/material";
import { ReactElement, useCallback } from "react";
import { useNavigate } from "react-router";

type PlanCardProps = {
  title: string;
  description: string;
  imageElement: ReactElement;
  linkLocation?: string;
  linkType?: "internal" | "external";
  onClick?: () => void;
};

const CARD_HEIGHT = 200;
const MAX_CHILD_WIDTH = Math.ceil((CARD_HEIGHT * 4) / 3);

const PlanCard = ({
  title,
  description,
  imageElement,
  linkLocation,
  linkType = "internal",
  onClick,
}: PlanCardProps) => {
  const navigate = useNavigate();
  const handleClick = useCallback(() => {
    linkType === "internal"
      ? navigate(linkLocation || "/")
      : publish({ topic: "openLinkExternally", data: { url: linkLocation } });
  }, [linkLocation, linkType, navigate]);
  return (
    <Box
      component={Paper}
      width="100%"
      height={CARD_HEIGHT}
      elevation={4}
      overflow="hidden"
      onClick={onClick || handleClick}
      sx={{ cursor: "pointer" }}
    >
      <Box display="flex" flexDirection="row" position="relative" justifyContent="space-between">
        <Box
          display="flex"
          flexDirection="column"
          p={2.5}
          gap={0.5}
          width="50%"
          maxWidth={MAX_CHILD_WIDTH}
          height={CARD_HEIGHT}
        >
          <Typography variant="prose-xl-bold">{title}</Typography>
          <Typography variant="prose-sm-medium" color="text.secondary">
            {description}
          </Typography>
        </Box>
        <Box
          height={CARD_HEIGHT}
          width="50%"
          maxWidth={MAX_CHILD_WIDTH}
          position="absolute"
          right={0}
          top={0}
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{
            maskImage: "radial-gradient(100% 170% at 100% 50%, #000 56%, transparent 100%)",
          }}
        >
          {imageElement}
        </Box>
      </Box>
    </Box>
  );
};

export default PlanCard;
