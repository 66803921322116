import { Dispatch, SetStateAction } from "react";
import { Box, FormControlLabel, Checkbox, TextField } from "@mui/material";
import useNumericEntry from "./useNumericEntry";
import { Responses } from "../useFourDPQuiz";

interface Props {
  initialVal?: number | "skip";
  min: number;
  max: number;
  skipText?: string;
  setCurrentStepHasValue: (val: boolean) => void;
  setCurrentStepValid: (val: boolean) => void;
  setResponses: Dispatch<SetStateAction<Responses | undefined>>;
  questionId: number;
}
function NumericEntry({
  initialVal,
  setCurrentStepHasValue,
  setCurrentStepValid,
  setResponses,
  questionId,
  min,
  max,
  skipText,
}: Props) {
  const { val, onChange, skipChecked, error, handleSkipToggle } = useNumericEntry({
    initialVal,
    setCurrentStepHasValue,
    setCurrentStepValid,
    setResponses,
    questionId,
    min,
    max,
  });
  return (
    <Box display="flex" flexDirection="column" width="100%" gap={2}>
      <TextField
        fullWidth
        value={val}
        onChange={onChange}
        type="number"
        helperText={!skipChecked ? error : ""}
        error={!!error && !skipChecked}
        disabled={skipChecked}
        inputProps={{ inputMode: "decimal", "data-testid": "4dp-quiz-numeric-entry" }}
      />
      <Box mx={2}>
        <FormControlLabel
          control={<Checkbox checked={skipChecked} onChange={handleSkipToggle} />}
          label={skipText}
        />
      </Box>
    </Box>
  );
}

export default NumericEntry;
