import { Box, Grid, InputAdornment, TextField } from "@mui/material";
import { ChangeEventHandler, FocusEventHandler, useCallback, useEffect, useState } from "react";
import { Trans, t } from "@lingui/macro";
import { ImperialMetricDisplayPreference } from "@WahooFitness/cloud-client-types";
import { truncateToTwoDecimalPlaces } from "@/services/truncateNumbers";

type Props = {
  value: number;
  unitPreference: ImperialMetricDisplayPreference;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
};

const HeightInput = ({ value, unitPreference, onBlur }: Props) => {
  const [feet, setFeet] = useState<string>("");
  const [inches, setInches] = useState<string>("");
  const [centimeters, setCentimeters] = useState<string>("");

  const [feetValid, setFeetValid] = useState<boolean>(true);
  const [inchesValid, setInchesValid] = useState<boolean>(true);
  const [centimetersValid, setCentimetersValid] = useState<boolean>(true);

  useEffect(() => {
    setFeet(Math.floor(value / 12).toString());
    setInches(truncateToTwoDecimalPlaces(value % 12));
    setCentimeters(truncateToTwoDecimalPlaces(value));
  }, [value]);

  const handleFeetBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> = useCallback(
    (event) => {
      if (!feetValid) {
        setFeet(Math.floor(value / 12).toString());
        setFeetValid(true);
        return;
      }

      const newValue = Math.round(+event.target.value);
      if (newValue !== +event.target.value) {
        setFeet(newValue.toString());
      }
      onBlur &&
        onBlur({
          ...event,
          target: { ...event.target, value: (newValue * 12 + +inches).toString() },
        });
    },
    [feetValid, inches, onBlur, value]
  );

  const handleInchesBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> = useCallback(
    (event) => {
      if (!inchesValid) {
        setInches(truncateToTwoDecimalPlaces(value % 12));
        setInchesValid(true);
        return;
      }

      const newValue = truncateToTwoDecimalPlaces(event.target.value);
      if (newValue !== event.target.value) {
        setInches(newValue.toString());
      }
      onBlur &&
        onBlur({
          ...event,
          target: { ...event.target, value: (+feet * 12 + +newValue).toString() },
        });
    },
    [feet, inchesValid, onBlur, value]
  );

  const handleCentimetersBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> =
    useCallback(
      (event) => {
        if (!centimetersValid) {
          setCentimeters(truncateToTwoDecimalPlaces(value));
          setCentimetersValid(true);
          return;
        }

        const newValue = truncateToTwoDecimalPlaces(event.target.value);
        if (newValue !== event.target.value) {
          setCentimeters(newValue);
        }
        onBlur &&
          onBlur({
            ...event,
            target: { ...event.target, value: newValue },
          });
      },
      [centimetersValid, onBlur, value]
    );

  const handleFeetChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = useCallback(
    (event) => {
      setFeetValid(
        !Number.isNaN(+event.target.value) && +event.target.value >= 2 && +event.target.value <= 7
      );

      setFeet(event.target.value);
    },
    []
  );

  const handleInchesChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> =
    useCallback((event) => {
      setInchesValid(
        event.target.value !== "" &&
          !Number.isNaN(+event.target.value) &&
          +event.target.value >= 0 &&
          +event.target.value < 12
      );

      setInches(event.target.value);
    }, []);

  const handleCentimetersChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> =
    useCallback((event) => {
      setCentimetersValid(
        !Number.isNaN(+event.target.value) &&
          +event.target.value >= 60 &&
          +event.target.value <= 242
      );

      setCentimeters(event.target.value);
    }, []);
  return (
    <Box display="flex" flexDirection="column">
      {unitPreference === ImperialMetricDisplayPreference.metric ? (
        <TextField
          label={t`Height`}
          value={centimeters}
          variant="outlined"
          type="number"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Trans>cm</Trans>
              </InputAdornment>
            ),
          }}
          inputProps={{ inputMode: "decimal", "data-testid": "metricHeightInput" }}
          onBlur={handleCentimetersBlur}
          onChange={handleCentimetersChange}
          error={!centimetersValid}
          helperText={centimetersValid ? undefined : t`Enter a value between 60 and 242`}
        />
      ) : (
        <Grid container columnSpacing={2}>
          <Grid item xs={6}>
            <TextField
              label={t`Height`}
              value={feet}
              variant="outlined"
              type="number"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Trans>ft</Trans>
                  </InputAdornment>
                ),
              }}
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
                "data-testid": "imperialFeetInput",
              }}
              onBlur={handleFeetBlur}
              onChange={handleFeetChange}
              error={!feetValid}
              helperText={feetValid ? undefined : t`Enter a value between 2 and 7`}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={inches}
              variant="outlined"
              type="number"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Trans>in</Trans>
                  </InputAdornment>
                ),
              }}
              inputProps={{ inputMode: "decimal", "data-testid": "imperialInchesInput" }}
              onBlur={handleInchesBlur}
              onChange={handleInchesChange}
              error={!inchesValid}
              helperText={inchesValid ? undefined : t`Enter a value less than 12`}
              fullWidth
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default HeightInput;
