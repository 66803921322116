import { Box, Chip, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useCallback, useEffect, useMemo } from "react";
import ErrorBoundary from "../ErrorBoundary";
import { t, Trans } from "@lingui/macro";
import { useHeaderContext } from "@/hooks/useHeaderContext";
import useFitnessProfile from "./useFitnessProfile";
import ProfileCard from "./ProfileCard";
import AthleteProfileFeature from "@/assets/AthleteProfileFeature.png";
import FourDPChart from "./FourDPChart";
import ProfileDescription from "./ProfileDescription";
import FitnessAssessment from "@/assets/FitnessAssessment.png";

import FitnessProfileAccordion from "./FitnessProfileAccordion";
import { InfoIcon } from "@WahooFitness/redesignr";
import useFitnessAssessmentClickHandler from "@/hooks/useFitnessAssessmentClickHandler";
const RiderType = () => {
  const { setNavHeader } = useHeaderContext();
  const {
    userFitnessProfilesIsLoading,
    userFitnessProfilesError,
    mutateFitnessProfile,
    riderTypeName,
    riderTypeIcon,
    hasNoFitnessProfile,
    profileDescription,
    cyclingFitnessProfilesData,
    handleQuestionnaireClick,
    showQuestionnaireCard,
    riderStrength,
    riderWeakness,
    openInfoDrawer,
    isRiderTypeEstimate,
  } = useFitnessProfile();
  const { handleFitnessTestClick } = useFitnessAssessmentClickHandler();
  const { breakpoints, palette } = useTheme();
  const isSmallScreen = useMediaQuery(breakpoints.down("sm"));

  const handleReload = useCallback(() => {
    mutateFitnessProfile();
  }, [mutateFitnessProfile]);

  const headerProps = useMemo(() => ({ title: t`Athlete profile`, disableBoxShadow: true }), []);
  useEffect(() => {
    setNavHeader(headerProps);
  });

  const chartValues = {
    FTP: cyclingFitnessProfilesData?.dim_1 || 0,
    MAP: cyclingFitnessProfilesData?.dim_2 || 0,
    AC: cyclingFitnessProfilesData?.dim_3 || 0,
    NM: cyclingFitnessProfilesData?.dim_4 || 0,
    rankFTP: cyclingFitnessProfilesData?.dim_1_rank || 0,
    rankMAP: cyclingFitnessProfilesData?.dim_2_rank || 0,
    rankAC: cyclingFitnessProfilesData?.dim_3_rank || 0,
    rankNM: cyclingFitnessProfilesData?.dim_4_rank || 0,
  };

  return (
    <ErrorBoundary
      isLoading={userFitnessProfilesIsLoading}
      error={userFitnessProfilesError}
      onReload={handleReload}
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box
          sx={{
            position: "relative",
            width: "100vw",
            backgroundColor: palette.background.paperElevation4,
          }}
          display="flex"
          pt={2}
          alignItems="center"
          flexDirection="column"
        >
          <Box
            id="inner-container-top-section"
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            maxWidth="sm"
            width="100%"
            gap={1.5}
            pb={3}
          >
            <Stack direction="row" alignItems="center" px={2} width="100%">
              <Typography variant="ui-4xl-bold">
                {hasNoFitnessProfile ? t`Not set` : riderTypeName}
              </Typography>
              {!hasNoFitnessProfile && (
                <Box width={24} height={24} ml={1.5}>
                  {riderTypeIcon}
                </Box>
              )}
              {isRiderTypeEstimate && (
                <Chip
                  label={t`Estimate`}
                  size="small"
                  sx={{
                    borderRadius: 0.5,
                    backgroundColor: "action.selected",
                    color: "text.primary",
                    maxWidth: "fit-content",
                    ml: "auto",
                  }}
                />
              )}
            </Stack>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              pb={2}
              width="100%"
              maxWidth="sm"
              gap={2}
            >
              <FourDPChart
                FTP={chartValues.FTP}
                MAP={chartValues.MAP}
                AC={chartValues.AC}
                NM={chartValues.NM}
                rankFTP={chartValues.rankFTP}
                rankMAP={chartValues.rankMAP}
                rankAC={chartValues.rankAC}
                rankNM={chartValues.rankNM}
                isEmpty={hasNoFitnessProfile}
              />
              <Box display="flex" flexDirection="row" gap={1} onClick={openInfoDrawer}>
                <InfoIcon fontSize="small" color="info" />
                <Typography variant="prose-xs-medium" color={palette.info.main}>
                  <Trans>How we calculate?</Trans>
                </Typography>
              </Box>
            </Box>
            <ProfileDescription
              hasNoFitnessProfile={hasNoFitnessProfile}
              profileDescription={profileDescription}
            />
            <Stack direction={{ xs: "column", sm: "row" }} spacing={1.5} width="100%">
              {riderStrength && (
                <FitnessProfileAccordion
                  type="Strength"
                  name={riderStrength.name}
                  description={riderStrength.description}
                  icon={riderStrength.icon}
                  isSmallScreen={isSmallScreen}
                />
              )}
              {riderWeakness && (
                <FitnessProfileAccordion
                  type="Weakness"
                  name={riderWeakness.name}
                  description={riderWeakness.description}
                  icon={riderWeakness.icon}
                  isSmallScreen={isSmallScreen}
                />
              )}
            </Stack>
          </Box>
        </Box>
        <Stack
          justifyContent="center"
          maxWidth="sm"
          px={2}
          gap={2}
          py={4}
          sx={{
            backgroundColor: palette.background.default,
          }}
        >
          <Typography variant="ui-md-bold" color="text.primary" mt={2}>
            {hasNoFitnessProfile ? t`Start` : t`Update`} profiling
          </Typography>
          {showQuestionnaireCard && (
            <ProfileCard
              icon={AthleteProfileFeature}
              title={t`Questionnaire`}
              tag={t`Estimate`}
              time={t`5 min`}
              buttonText={t`Start questionnaire`}
              description={t`Estimate your strength and weaknesses as an athlete.`}
              onClick={handleQuestionnaireClick}
            />
          )}
          <ProfileCard
            icon={FitnessAssessment}
            title={t`Fitness Assessment`}
            tag={t`Most accurate`}
            time={t`59 min`}
            buttonText={t`View workout`}
            description={t`A workout designed to measure your power in different intensity and time to precisely identify all 4 metrics.`}
            onClick={handleFitnessTestClick}
          />
        </Stack>
      </Box>
    </ErrorBoundary>
  );
};

export default RiderType;
