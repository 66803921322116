import { getWorkoutTypeNames } from "@/components/WorkoutProfiles/AddWorkoutProfile/WorkoutTypeNames";
import { getWorkoutTypeFamilyIcon } from "@/services/getWorkoutTypeIcon";
import { FormControl, FormGroup, Stack } from "@mui/material";
import { WahooWorkoutTypeFamily } from "@WahooFitness/cloud-client-types";
import {
  RedesignrCheckbox,
  RedesignrFormControlLabel,
  RedesignrFormControlLabelContent,
} from "@WahooFitness/redesignr";
import { ChangeEvent, Dispatch, SetStateAction, useCallback, useMemo } from "react";

const allowedSports = [
  WahooWorkoutTypeFamily.Cycling,
  WahooWorkoutTypeFamily.Running,
  WahooWorkoutTypeFamily.Swimming,
  WahooWorkoutTypeFamily.Water,
  WahooWorkoutTypeFamily.Snow,
  WahooWorkoutTypeFamily.Skating,
  WahooWorkoutTypeFamily.Yoga,
  WahooWorkoutTypeFamily.Strength,
  WahooWorkoutTypeFamily.Walking,
  WahooWorkoutTypeFamily.Multisport,
  WahooWorkoutTypeFamily.Other,
];
interface SportSelectProps {
  selectedSportOptions: Set<WahooWorkoutTypeFamily>;
  setSelectedSportOptions: Dispatch<SetStateAction<Set<WahooWorkoutTypeFamily>>>;
}

function SportSelect({ selectedSportOptions, setSelectedSportOptions }: SportSelectProps) {
  const workoutTypeNames = useMemo(() => getWorkoutTypeNames(), []);
  const handleChange = useCallback(
    (ev: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const sportType = Number(ev.target.name);
      const newSelectedSportOptions = new Set(selectedSportOptions);
      if (checked) {
        newSelectedSportOptions.add(sportType);
      } else {
        newSelectedSportOptions.delete(sportType);
      }
      setSelectedSportOptions(newSelectedSportOptions);
    },
    [selectedSportOptions, setSelectedSportOptions]
  );

  return (
    <FormControl component="fieldset" variant="standard">
      <FormGroup>
        <Stack direction="row" flexWrap="wrap" gap={1.5}>
          {allowedSports.map((family) => {
            const Icon = getWorkoutTypeFamilyIcon(family);
            return (
              <RedesignrFormControlLabel
                key={family}
                control={
                  <RedesignrCheckbox
                    checked={selectedSportOptions.has(Number(family))}
                    onChange={handleChange}
                    name={String(family)}
                  />
                }
                label={
                  <RedesignrFormControlLabelContent
                    label={workoutTypeNames[WahooWorkoutTypeFamily[family]]}
                    icon={<Icon />}
                  />
                }
              />
            );
          })}
        </Stack>
      </FormGroup>
    </FormControl>
  );
}

export default SportSelect;
