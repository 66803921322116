import { ChangeEvent, useCallback } from "react";
import { t } from "@lingui/macro";
import { FormControl } from "@mui/material";
import {
  RedesignrFormControlLabel,
  RedesignrFormControlLabelContent,
  RedesignrRadio,
  RedesignrRadioGroup,
} from "@WahooFitness/redesignr";
import Sex from "@/services/types/Sex";

interface SexProps {
  userSex: Sex | null;
  setUserSex: (sex: Sex) => void;
}

function SexSelect({ userSex, setUserSex }: SexProps) {
  const handleChange = useCallback(
    (_ev: ChangeEvent<HTMLInputElement>, value: string) => {
      setUserSex(Number(value));
    },
    [setUserSex]
  );

  return (
    <FormControl sx={{ width: "100%" }}>
      <RedesignrRadioGroup
        name="sex-select-radio-button-group"
        onChange={handleChange}
        value={String(userSex)}
      >
        <RedesignrFormControlLabel
          value="1"
          control={<RedesignrRadio />}
          label={<RedesignrFormControlLabelContent align="center" label={t`Female`} />}
        />
        <RedesignrFormControlLabel
          value="0"
          control={<RedesignrRadio />}
          label={<RedesignrFormControlLabelContent align="center" label={t`Male`} />}
        />
        <RedesignrFormControlLabel
          value="3"
          control={<RedesignrRadio />}
          label={
            <RedesignrFormControlLabelContent align="center" label={t`Prefer not to answer`} />
          }
        />
      </RedesignrRadioGroup>
    </FormControl>
  );
}

export default SexSelect;
