import { Dialog, DialogContent, Slide, SlideProps, useTheme } from "@mui/material";
import { PropsWithChildren } from "react";
import NavHeader, { Props as HeaderProps } from "@/components/NavHeader";

interface Props {
  slideDirection: SlideProps["direction"];
  open: boolean;
  handleClose: () => void;
  headerProps?: HeaderProps;
}

const FullScreenDialog = ({
  children,
  slideDirection,
  open,
  handleClose,
  headerProps,
}: PropsWithChildren<Props>) => {
  const { palette } = useTheme();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen
      PaperProps={{
        sx: { background: palette.background.paper },
      }}
    >
      {headerProps && <NavHeader {...headerProps} />}
      <Slide in={open} direction={slideDirection}>
        <DialogContent sx={{ padding: 0, width: "100%", maxWidth: "sm", margin: "auto" }}>
          {children}
        </DialogContent>
      </Slide>
    </Dialog>
  );
};

export default FullScreenDialog;
