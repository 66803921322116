import { t, Trans } from "@lingui/macro";
import { KeyboardEventHandler, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Box, Button, InputAdornment, TextField, Typography, useTheme } from "@mui/material";
import { useLiveTrack } from "./useLiveTrack";
import EmailValidator from "email-validator";
import FullScreenDialog from "../FullScreenDialog";
import { useNativeMessagingContext } from "@WahooFitness/wahoo-offline-mfe";
import { MenuList, MenuListItemType, MenuListItemVariant } from "@WahooFitness/redesignr";
import { ArrowUpLeft, CloseFilled } from "@carbon/icons-react";
import { useFuzzySearchList } from "@nozbe/microfuzz/react";
import { FuzzyResult } from "@nozbe/microfuzz";

type NativeContact = { name: string; email: string };

const getText = (contact: NativeContact) => [contact.name, contact.email];
const mapResultItem = (val: FuzzyResult<NativeContact>) => val.item;

const AddLiveTrackContact = ({ open, handleClose }: { open: boolean; handleClose: () => void }) => {
  const { palette } = useTheme();
  const ref = useRef<HTMLElement>();
  const [nativeContacts, setNativeContacts] = useState<NativeContact[]>([]);
  const { addEventResponseListener, removeEventResponseListener } = useNativeMessagingContext();

  useEffect(() => {
    addEventResponseListener("getNativeContacts", (eventResponse) => {
      setNativeContacts(eventResponse.eventResponseData as NativeContact[]);
    });
    return () => removeEventResponseListener("getNativeContacts");
  }, [addEventResponseListener, removeEventResponseListener]);

  const { addContact } = useLiveTrack();

  const [email, setEmail] = useState<string>("");

  // Search for contacts by name or email
  const matchingContacts = useFuzzySearchList({
    list: nativeContacts,
    queryText: email,
    getText,
    mapResultItem,
  });

  const error = useMemo(() => {
    return email.length === 0 || EmailValidator.validate(email) ? null : t`Invalid email address`;
  }, [email]);

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  }, []);

  const handleAddContact = useCallback(async () => {
    if (error || email.length === 0) {
      return;
    }
    if (!(await addContact(email))) {
      return;
    }
    setEmail("");
    setNativeContacts([]);
    handleClose();
  }, [addContact, email, error, handleClose]);

  const handleCancel = useCallback(() => {
    setEmail("");
    setNativeContacts([]);
    handleClose();
  }, [handleClose]);

  const handleKeyboardCommands: KeyboardEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      if (event.key === "Enter") {
        if (!error && email.length > 0) {
          handleAddContact();
        }
        event.stopPropagation();
      }
    },
    [email.length, error, handleAddContact]
  );

  const handleContactSelect = useCallback((email: string) => {
    setEmail(email);
    ref.current?.scrollIntoView({ behavior: "smooth", block: "end" });
  }, []);

  const handleClearClick = useCallback(() => {
    setEmail("");
  }, []);

  const contactItems = useMemo(
    (): MenuListItemType[] =>
      matchingContacts.map((contact) => ({
        id: contact.email,
        content: (
          <Box>
            <Typography variant="prose-base" color={palette.primary.main}>
              {contact.name}
            </Typography>
          </Box>
        ),
        secondaryContent: (
          <Typography variant="prose-sm" color={palette.primary.main}>
            {contact.email}
          </Typography>
        ),
        variant: MenuListItemVariant.Action,
        action: () => handleContactSelect(contact.email),
        actionComponent: <ArrowUpLeft width={24} height={24} />,
      })),
    [handleContactSelect, matchingContacts, palette.primary.main]
  );

  return (
    <FullScreenDialog
      slideDirection="up"
      open={open}
      handleClose={handleCancel}
      headerProps={{
        title: t`Add recipient`,
        backAction: handleCancel,
        backText: t`Cancel`,
        headerAction: (
          <Button disabled={!!error || email.length === 0} onClick={handleAddContact}>
            <Trans>Add</Trans>
          </Button>
        ),
      }}
    >
      <Box m={2} display="flex" flexDirection="column" gap={2}>
        <TextField
          inputRef={ref}
          value={email}
          onChange={handleChange}
          error={!!error}
          label={t`Email address`}
          helperText={error ?? t`Search by name, email, or nickname`}
          onKeyUp={handleKeyboardCommands}
          variant="outlined"
          InputProps={{
            endAdornment: email ? (
              <InputAdornment position="end">
                <CloseFilled size={24} onClick={handleClearClick} />
              </InputAdornment>
            ) : undefined,
          }}
        />
        <MenuList disableGutters listItems={contactItems} />
      </Box>
    </FullScreenDialog>
  );
};

export { AddLiveTrackContact };
