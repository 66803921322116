import { ImperialMetricDisplayPreference } from "@WahooFitness/cloud-client-types";
import { Stack } from "@mui/system";
import UnitPreferenceToggle from "../../User/AboutYou/UnitPreferenceToggle";
import { t } from "@lingui/macro";
import { TextField } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { truncateToTwoDecimalPlaces } from "@/services/truncateNumbers";
import {
  convertCentimetersToMeters,
  convertInchesToMeters,
  convertMetersToCentimeters,
  convertMetersToInches,
} from "@/services/convertUnits";
import useDebounce from "@/hooks/useDebounce";

type Props = {
  userHeight: string | null;
  setUserHeight: (height: string | null) => void;
  userHeightUnit: ImperialMetricDisplayPreference | null | undefined;
  setUserHeightUnit: (unit: ImperialMetricDisplayPreference) => void;
  setIsHeightValid: (value: boolean) => void;
};

export const OnboardingHeightInput = ({
  setUserHeight,
  userHeight,
  userHeightUnit,
  setUserHeightUnit,
  setIsHeightValid,
}: Props) => {
  const [feet, setFeet] = useState<string | null>(
    userHeight ? Math.floor(+convertMetersToInches(+userHeight) / 12).toString() : null
  );
  const [inches, setInches] = useState<string | null>(
    userHeight ? (convertMetersToInches(+userHeight) % 12).toString() : null
  );
  const [centimeters, setCentimeters] = useState<string | null>(
    userHeight ? convertMetersToCentimeters(+userHeight).toString() : null
  );
  const debouncedCentimeters = useDebounce(centimeters, 500);
  const hasFeetError = !!feet && (+feet < 2 || +feet > 7);
  const hasCentimetersError =
    !!debouncedCentimeters && (+debouncedCentimeters < 59 || +debouncedCentimeters > 242);
  const hasInchesError = !!inches && (+inches < 0 || +inches >= 12);

  useEffect(() => {
    if (userHeightUnit === ImperialMetricDisplayPreference.imperial) {
      setIsHeightValid(!!feet && !hasFeetError && !hasInchesError);
    } else {
      setIsHeightValid(!!debouncedCentimeters && !hasCentimetersError);
    }
  }, [
    debouncedCentimeters,
    feet,
    hasCentimetersError,
    hasFeetError,
    hasInchesError,
    setIsHeightValid,
    userHeightUnit,
  ]);

  const handleUnitChange = (
    _event: React.MouseEvent<HTMLElement>,
    value: "metric" | "imperial"
  ) => {
    if (value === null) {
      return;
    }
    if (value === "metric") {
      const feetToInches = +(feet ?? 0) * 12;
      const totalInches = feetToInches + +(inches ?? 0);
      const convertedCentimeters = (convertInchesToMeters(totalInches) * 100).toString();
      setCentimeters(truncateToTwoDecimalPlaces(convertedCentimeters));
    } else {
      if (centimeters) {
        const convertedIn = convertMetersToInches(+centimeters / 100);
        const convertedFt = Math.floor(convertedIn / 12);
        const remainingInches = convertedIn % 12;
        setFeet(convertedFt.toString());
        setInches(truncateToTwoDecimalPlaces(remainingInches));
      }
    }
    setUserHeightUnit(
      value === "imperial"
        ? ImperialMetricDisplayPreference.imperial
        : ImperialMetricDisplayPreference.metric
    );
  };

  const handleCentimetersBlur = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!!event.target.value && (+event.target.value < 59 || +event.target.value > 242)) {
      setUserHeight(null);
    } else {
      setCentimeters(truncateToTwoDecimalPlaces(+event.target.value));
      setUserHeight(convertCentimetersToMeters(+event.target.value).toString());
    }
  };

  const handleFeetBlur = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!!event.target.value && (+event.target.value < 2 || +event.target.value > 7)) {
      setUserHeight(null);
    } else {
      const totalInches = +event.target.value * 12 + +(inches ?? 0);
      setFeet(Math.floor(+event.target.value).toString());
      setUserHeight(convertInchesToMeters(totalInches).toString());
    }
  };

  const handleInchesBlur = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!!event.target.value && (+event.target.value < 0 || +event.target.value >= 12)) {
      setUserHeight(null);
    } else {
      const totalInches = +event.target.value + +(feet ?? 0) * 12;
      setInches(truncateToTwoDecimalPlaces(+event.target.value));
      setUserHeight(convertInchesToMeters(totalInches).toString());
    }
  };

  const handleOnCentimetersChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setCentimeters(event.target.value);
  };

  const handleOnFeetChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFeet(event.target.value);
  };

  const handleOnInchesChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setInches(event.target.value);
  };

  return (
    <Stack spacing={2.5} width="100%">
      <UnitPreferenceToggle
        value={userHeightUnit === ImperialMetricDisplayPreference.imperial ? "imperial" : "metric"}
        onChange={handleUnitChange}
        hideCustom
        hideUomText
        toggleGroupColor="info"
      />
      {userHeightUnit === ImperialMetricDisplayPreference.metric ? (
        <TextField
          label={t`Centimeters`}
          value={centimeters}
          variant="outlined"
          type="number"
          inputProps={{ inputMode: "decimal", "data-testid": "metricHeightInput" }}
          onBlur={handleCentimetersBlur}
          onChange={handleOnCentimetersChange}
          error={hasCentimetersError}
          helperText={hasCentimetersError ? "Enter a value between 60 and 242" : null}
          autoFocus
        />
      ) : (
        <Stack direction="row" gap={1.5}>
          <TextField
            label={t`Feet`}
            value={feet}
            variant="outlined"
            type="number"
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
              "data-testid": "imperialFeetInput",
            }}
            onBlur={handleFeetBlur}
            onChange={handleOnFeetChange}
            error={hasFeetError}
            helperText={hasFeetError ? "Enter a value between 2 and 7" : null}
            fullWidth
            autoFocus
          />

          <TextField
            label={t`Inches`}
            value={inches}
            variant="outlined"
            type="number"
            inputProps={{ inputMode: "decimal", "data-testid": "imperialInchesInput" }}
            onBlur={handleInchesBlur}
            onChange={handleOnInchesChange}
            error={hasInchesError}
            helperText={hasInchesError ? "Enter a value less than 12" : null}
            fullWidth
          />
        </Stack>
      )}
    </Stack>
  );
};
