import { Settings, Information, NotebookReference } from "@carbon/icons-react";
import { t } from "@lingui/macro";
import { useTheme } from "@mui/system";
import { ChevronRightIcon, MenuListItemType, MenuListItemVariant } from "@WahooFitness/redesignr";
import { useEffect, useMemo } from "react";
import { ReactRouterLinkWrapper } from "../MenuList/ReactRouterLinkWrapper";
import { crux_bolt_type_e } from "@WahooFitness/crux-js-display-cfg";
import {
  getStoredValue,
  setStoredValue,
  useAnalyticsContext,
  useConfigContext,
} from "@WahooFitness/wahoo-offline-mfe";
import { useNavigate } from "react-router";
import useBCAutoPair from "@/hooks/useBCAutoPair";
import useFlaggedFeatures from "@/hooks/useFlaggedFeatures";
import usePairedDevice from "@/hooks/usePairedDevice";
import useAssociatedDevices from "@/hooks/useAssociatedDevices";
import useSWR from "swr";
import { useCheckForOnboardingEffect } from "./useCheckForOnboardingEffect";
import { FlaggedFeature } from "@/hooks/types/FlaggedFeature";

const useDeviceConfig = (appToken: string) => {
  const { palette } = useTheme();
  const { getRemoteConfigString } = useAnalyticsContext();
  const { bcAutoPairing } = useConfigContext();
  const { startPairing, pairingFailed, pairing } = useBCAutoPair();
  const { checkIsFeatureEnabled, featuresAreLoading } = useFlaggedFeatures();
  const navigate = useNavigate();

  const {
    device,
    deviceLoading,
    reloadDevice,
    isDeviceConnected,
    connectionStateDisplay,
    unpairDevice,
    setDeviceName,
  } = usePairedDevice(appToken);

  // Get out if onboarding isn't finished.
  useCheckForOnboardingEffect(appToken);

  const { associatedDevices } = useAssociatedDevices();

  useEffect(() => {
    if (appToken && device?.serialNumber) {
      setStoredValue(`${appToken}_serialNumber`, device.serialNumber);
    }
  }, [appToken, device?.serialNumber]);

  const { data: savedSerialNumber } = useSWR(`${appToken}_serialNumber`, getStoredValue);

  const deviceInfo = useMemo(() => {
    const serialNumber = device?.serialNumber ?? savedSerialNumber;

    let firmwareVersion = device?.firmwareVersion;
    if (!firmwareVersion && serialNumber && associatedDevices) {
      const associatedDevice = associatedDevices.find(
        (device) => device.serial_number === serialNumber
      );
      if (associatedDevice) {
        firmwareVersion = `${associatedDevice.firmware_version}-${associatedDevice.software_version}`;
      }
    }

    return {
      firmwareVersion: firmwareVersion ?? t`Unknown`,
      serialNumber: serialNumber ?? t`Unknown`,
    };
  }, [associatedDevices, device?.firmwareVersion, device?.serialNumber, savedSerialNumber]);

  useEffect(() => {
    if (!deviceLoading) {
      const interval = setInterval(() => {
        reloadDevice();
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [deviceLoading, reloadDevice]);

  const remoteSetUpGuideUrls = useMemo(
    (): Record<number, string | undefined> => ({
      [crux_bolt_type_e.ROAM]: getRemoteConfigString("ROAM_1_SETUP_GUIDE_URL"),
      [crux_bolt_type_e.ROAM_1_1]: getRemoteConfigString("ROAM_1_SETUP_GUIDE_URL"),
      [crux_bolt_type_e.ROAM_2]: getRemoteConfigString("ROAM_2_SETUP_GUIDE_URL"),
      [crux_bolt_type_e.BOLT]: getRemoteConfigString("BOLT_1_SETUP_GUIDE_URL"),
      [crux_bolt_type_e.BOLT_2]: getRemoteConfigString("BOLT_2_SETUP_GUIDE_URL"),
      [crux_bolt_type_e.ACE]: getRemoteConfigString("ACE_SETUP_GUIDE_URL"),
    }),
    [getRemoteConfigString]
  );

  const setUpGuideUrl = useMemo(
    () => (device?.boltType !== undefined ? remoteSetUpGuideUrls[device.boltType] : ""),
    [device?.boltType, remoteSetUpGuideUrls]
  );

  const profilesSettingsMenu = useMemo(
    (): { id: string; items: MenuListItemType[] } => ({
      id: "profileSettings",
      items: [
        {
          id: "workout-profiles",
          icon: <Settings size={24} color={palette.text.primary} />,
          content: t`Workout profiles`,
          variant: MenuListItemVariant.InternalLink,
          linkLocation: `/workout-profiles?familyFilter=0`, // Only show biking profiles
          linkComponent: ReactRouterLinkWrapper,
        },
        {
          id: "settings",
          icon: <Settings size={24} color={palette.text.primary} />,
          content: t`Settings`,
          variant: MenuListItemVariant.InternalLink,
          linkLocation: `/device-config/${appToken}/settings`,
          disabled: !isDeviceConnected,
          linkComponent: ReactRouterLinkWrapper,
        },
      ],
    }),
    [appToken, isDeviceConnected, palette.text.primary]
  );

  const deviceInfoSetupMenu = useMemo(
    (): { id: string; items: MenuListItemType[] } => ({
      id: "deviceInfoSetup",
      items: [
        {
          id: "device-information",
          icon: <Information size="24px" color={palette.text.primary} />,
          content: t`Device information`,
          variant: MenuListItemVariant.Action,
          action: () => {
            navigate(`/device-config/${appToken}/info`, {
              state: {
                firmwareVersion: deviceInfo.firmwareVersion,
                serialNumber: deviceInfo.serialNumber,
              },
            });
          },
          actionComponent: <ChevronRightIcon sx={{ color: palette.text.secondary }} />,
        },
        {
          id: "set-up-guide",
          icon: <NotebookReference size="24px" color={palette.text.primary} />,
          content: t`Set up guide`,
          variant: MenuListItemVariant.ExternalLink,
          linkLocation: setUpGuideUrl,
        },
      ],
    }),
    [
      appToken,
      deviceInfo.firmwareVersion,
      deviceInfo.serialNumber,
      navigate,
      palette.text.primary,
      palette.text.secondary,
      setUpGuideUrl,
    ]
  );

  const sections = useMemo(() => {
    return [profilesSettingsMenu, deviceInfoSetupMenu];
  }, [deviceInfoSetupMenu, profilesSettingsMenu]);

  useEffect(() => {
    if (!device && bcAutoPairing && !pairingFailed && !pairing && appToken) {
      startPairing(appToken, reloadDevice);
    }
  }, [appToken, bcAutoPairing, device, pairing, pairingFailed, reloadDevice, startPairing]);

  const deviceConfigLoading = useMemo(
    () => featuresAreLoading || deviceLoading || (!device && bcAutoPairing && !pairingFailed),
    [bcAutoPairing, device, deviceLoading, featuresAreLoading, pairingFailed]
  );

  const deviceConfigEnabled = useMemo(
    () => checkIsFeatureEnabled(FlaggedFeature.ElemntConfig),
    [checkIsFeatureEnabled]
  );

  return {
    deviceConfigEnabled,
    deviceConfigLoading,
    device,
    unpairDevice,
    setDeviceName,
    connectionStateDisplay,
    sections,
  };
};

export { useDeviceConfig };
