import {
  ToggleButton,
  ToggleButtonGroup,
  ToggleButtonGroupPropsColorOverrides,
  Typography,
} from "@mui/material";
import React from "react";
import { Trans } from "@lingui/macro";
import { OverridableStringUnion } from "@mui/types";

type Props = {
  value: "metric" | "imperial" | "custom";
  onChange?: (event: React.MouseEvent<HTMLElement, MouseEvent>, value: any) => void;
  hideCustom?: boolean;
  hideUomText?: boolean;
  // once we update settings to have consistent design, we can remove toggleGroupColor prop
  toggleGroupColor?: OverridableStringUnion<
    "standard" | "primary" | "secondary" | "error" | "info" | "success" | "warning",
    ToggleButtonGroupPropsColorOverrides
  >;
};

const UnitPreferenceToggle = ({
  value,
  onChange,
  hideCustom,
  hideUomText,
  toggleGroupColor = "primary",
}: Props) => {
  return (
    <>
      {!hideUomText && (
        <Typography variant="ui-base-medium" pb={1} color="text.primary">
          <Trans>Unit of measure</Trans>
        </Typography>
      )}
      <ToggleButtonGroup
        exclusive
        value={value}
        fullWidth
        onChange={onChange}
        color={toggleGroupColor}
      >
        <ToggleButton value="metric" data-testid="metricToggle">
          <Typography variant="ui-sm" color="primary">
            <Trans>Metric</Trans>
          </Typography>
        </ToggleButton>
        <ToggleButton value="imperial" data-testid="imperialToggle">
          <Typography variant="ui-sm" color="primary">
            <Trans>Imperial</Trans>
          </Typography>
        </ToggleButton>
        {!hideCustom && (
          <ToggleButton value="custom" data-testid="customToggle">
            <Typography variant="ui-sm" color="primary">
              <Trans>Custom</Trans>
            </Typography>
          </ToggleButton>
        )}
      </ToggleButtonGroup>
    </>
  );
};

export default UnitPreferenceToggle;
