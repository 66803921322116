import UnitPreferenceToggle from "@/components/User/AboutYou/UnitPreferenceToggle";
import { useWeight } from "@/hooks/useWeight";
import { convertKilogramsToPounds, convertPoundsToKilograms } from "@/services/convertUnits";
import { truncateToTwoDecimalPlaces } from "@/services/truncateNumbers";
import { t } from "@lingui/macro";
import { Stack, TextField } from "@mui/material";
import { ImperialMetricDisplayPreference } from "@WahooFitness/cloud-client-types";
import { useCallback, FocusEventHandler, useEffect } from "react";

type Props = {
  userWeight: number | null;
  userUnitPreference: ImperialMetricDisplayPreference;
  setUserWeight: (weight: string) => void;
  setUserUnitPreference: (unit: ImperialMetricDisplayPreference) => void;
  isWeightValid: boolean;
  setIsWeightValid: (value: boolean) => void;
};
export const OnboardingWeightInput = ({
  userWeight,
  setUserWeight,
  userUnitPreference,
  setUserUnitPreference,
  isWeightValid,
  setIsWeightValid,
}: Props) => {
  const handleOnBlur: FocusEventHandler<HTMLTextAreaElement | HTMLInputElement> = useCallback(
    (event) => {
      const newValue =
        userUnitPreference === ImperialMetricDisplayPreference.metric
          ? +event.target.value
          : convertPoundsToKilograms(+event.target.value);
      setUserWeight(newValue.toString());
    },
    [setUserWeight, userUnitPreference]
  );

  const {
    errorMessage,
    handleChange,
    currentValue,
    handleUnitPreferenceChange,
    setCurrentValue,
    handleBlur,
  } = useWeight({
    initialWeight: userWeight,
    unitPreference: userUnitPreference,
    setUnitPreference: setUserUnitPreference,
    onBlur: handleOnBlur,
    isWeightValid,
    setIsWeightValid,
  });

  useEffect(() => {
    if (userWeight && userWeight !== 0) {
      if (userUnitPreference === ImperialMetricDisplayPreference.metric) {
        setCurrentValue(truncateToTwoDecimalPlaces(userWeight));
      } else {
        setCurrentValue(truncateToTwoDecimalPlaces(convertKilogramsToPounds(userWeight)));
      }
    }
  }, [userWeight, userUnitPreference, setCurrentValue]);

  return (
    <Stack spacing={2.5} width="100%">
      <UnitPreferenceToggle
        value={
          userUnitPreference === ImperialMetricDisplayPreference.imperial ? "imperial" : "metric"
        }
        onChange={handleUnitPreferenceChange}
        hideCustom
        hideUomText
        toggleGroupColor="info"
      />
      <TextField
        label={userUnitPreference === ImperialMetricDisplayPreference.imperial ? t`lbs` : t`kg`}
        value={currentValue}
        variant="outlined"
        type="number"
        inputProps={{ inputMode: "decimal", "data-testid": "weightInput" }}
        onBlur={handleBlur}
        onChange={handleChange}
        error={!!errorMessage}
        helperText={errorMessage}
        autoFocus
      />
    </Stack>
  );
};
