const KILOGRAMS_TO_POUNDS = 2.20462262185;
const YARDS_TO_METERS = 0.9144;
const MILES_TO_YARDS = 1760;
const KM_TO_METERS = 1000;
const MINUTES_TO_SECONDS = 60;
const INCHES_TO_METERS = 0.0254;
const METERS_TO_CENTIMETERS = 100;

export const convertPoundsToKilograms = (pounds: number) => {
  return pounds / KILOGRAMS_TO_POUNDS;
};

export const convertKilogramsToPounds = (kilograms: number) => {
  return kilograms * KILOGRAMS_TO_POUNDS;
};

export const convertYardsToMeters = (yards: number) => {
  return yards * YARDS_TO_METERS;
};

export const convertMetersToYards = (meters: number) => {
  return meters / YARDS_TO_METERS;
};

export const convertYardsToMiles = (yards: number) => {
  return yards / MILES_TO_YARDS;
};

export const convertMilesToYards = (miles: number) => {
  return miles * MILES_TO_YARDS;
};

export const convertMetersToKilometers = (meters: number) => {
  return meters / KM_TO_METERS;
};

export const convertKilometersToMeters = (kilometers: number) => {
  return kilometers * KM_TO_METERS;
};

export const convertMinutesToSeconds = (minutes: number) => {
  return minutes * MINUTES_TO_SECONDS;
};

export const convertSecondsToMinutes = (seconds: number) => {
  return seconds / MINUTES_TO_SECONDS;
};

export const convertInchesToMeters = (inches: number) => {
  return inches * INCHES_TO_METERS;
};

export const convertMetersToInches = (meters: number) => {
  return meters / INCHES_TO_METERS;
};

export const convertCentimetersToMeters = (centimeters: number) => {
  return centimeters / METERS_TO_CENTIMETERS;
};

export const convertMetersToCentimeters = (meters: number) => {
  return meters * METERS_TO_CENTIMETERS;
};
