import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect } from "react";
import { Trans } from "@lingui/macro";
import { useHeaderContext } from "@/hooks/useHeaderContext";
import { ChevronDownIcon } from "@WahooFitness/redesignr";
import FitnessAssessment from "@/assets/FitnessAssessment.png";
import useFitnessAssessmentClickHandler from "@/hooks/useFitnessAssessmentClickHandler";
import { Time } from "@carbon/icons-react";

const FourDPQuizResults = () => {
  const { setNavHeader } = useHeaderContext();
  const { handleFitnessTestClick } = useFitnessAssessmentClickHandler();

  const { palette } = useTheme();

  useEffect(() => {
    setNavHeader({});
  });

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        height="100%"
        overflow="scroll"
      >
        <Stack
          width="100%"
          justifyContent="center"
          maxWidth="sm"
          px={2}
          gap={2}
          py={4}
          sx={{
            backgroundColor: palette.background.default,
          }}
        >
          <Accordion
            sx={{
              width: "100%",
              backgroundColor: palette.background.paperElevation4,
              px: 0,
              borderRadius: 2,
              ":last-of-type": {
                borderRadius: 2,
              },
            }}
            variant="outlined"
            disableGutters
          >
            <AccordionSummary expandIcon={<ChevronDownIcon />}>
              <Stack direction="row" alignItems="center">
                <img
                  src={FitnessAssessment}
                  alt="more-accurate-results"
                  style={{ height: 44, width: 44 }}
                />
                <Typography variant="prose-base-medium" color="primary" sx={{ ml: 1.5 }}>
                  <Trans>For more accurate results take fitness assessment.</Trans>
                </Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <Stack gap={1} mt={0.5}>
                <Typography variant="prose-sm" color="text.secondary">
                  <Trans>
                    The Full Frontal workout is designed to measure your power in different
                    intensity and time to precisely identify all 4 metrics.
                  </Trans>
                </Typography>
                <Box display="flex" alignItems="center" gap={0.5}>
                  <Time color="text.secondary" height={16} width={16} />
                  <Typography variant="ui-base-medium" color="text.primary">
                    <Trans>59 min</Trans>
                  </Typography>
                </Box>
                <Button
                  variant="contained"
                  size="large"
                  fullWidth
                  color="secondary"
                  onClick={handleFitnessTestClick}
                  sx={{ mt: 1, mb: 0.5 }}
                >
                  <Typography variant="prose-md-medium" color="secondary.contrast">
                    <Trans>View workout</Trans>
                  </Typography>
                </Button>
                <Typography variant="ui-sm" color="info.main" textAlign="center">
                  <Trans>You can find it in your account and do it later.</Trans>
                </Typography>
              </Stack>
            </AccordionDetails>
          </Accordion>
        </Stack>
      </Box>
      <Box
        sx={{
          position: "absolute",
          width: "100vw",
          backgroundColor: palette.background.paperElevation4,
          left: 0,
          bottom: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          borderTop: `1px solid ${palette.divider}`,
        }}
      >
        <Box
          py={1.5}
          px={2}
          gap={1.5}
          maxWidth="sm"
          display="flex"
          flexDirection="column"
          width="100%"
          justifyContent="center"
        >
          <Button
            variant="contained"
            size="large"
            fullWidth
            color="primary"
            onClick={() => alert("TODO")}
          >
            <Trans>Save results</Trans>
          </Button>
          <Button variant="text" size="large" fullWidth color="error" onClick={() => alert("TODO")}>
            <Trans>Discard results</Trans>
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default FourDPQuizResults;
