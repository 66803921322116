import { useMemo } from "react";
import { Box, Button, IconButton, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { t, Trans } from "@lingui/macro";
import { WahooWorkoutTypeFamily } from "@WahooFitness/cloud-client-types";
import { ChevronLeft } from "@carbon/icons-react";
import CyclingTabletBackground from "@/assets/CyclingTabletBackground.png";
import RunningTabletBackground from "@/assets/RunningTabletBackground.png";
import DeviceManagementFeature from "@/assets/DeviceManagementFeature.png";
import AthleteProfileFeature from "@/assets/AthleteProfileFeature.png";
import WorkoutAnalysisFeature from "@/assets/WorkoutAnalysisFeature.png";
import RecordWorkoutsFeature from "@/assets/RecordWorkoutsFeature.png";
import { RedesignrDarkTheme } from "@WahooFitness/redesignr";

const AthleteOnboardingFeatureScreen = ({
  selectedSportOptions,
  handleBack,
  handleNext,
}: {
  selectedSportOptions: Set<WahooWorkoutTypeFamily>;
  handleBack: () => void;
  handleNext: () => void;
}) => {
  const { breakpoints } = useTheme();
  const isSmallScreen = useMediaQuery(breakpoints.down("sm"));
  const backgroundImage = useMemo(() => {
    if (
      selectedSportOptions.has(WahooWorkoutTypeFamily.Running) &&
      !selectedSportOptions.has(WahooWorkoutTypeFamily.Cycling)
    ) {
      return `url(${RunningTabletBackground})`;
    }
    return `url(${CyclingTabletBackground})`;
  }, [selectedSportOptions]);

  const featuresList = [
    {
      text: t`Device management`,
      icon: DeviceManagementFeature,
    },
    {
      text: t`Power profiling`,
      icon: AthleteProfileFeature,
    },
    {
      text: t`Workout analysis`,
      icon: WorkoutAnalysisFeature,
    },
    {
      text: t`Workout recording`,
      icon: RecordWorkoutsFeature,
    },
  ];

  const handleOnBack = () => {
    handleBack();
  };

  const handleCompleteOnboarding = () => {
    handleNext();
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      height="100%"
      sx={{
        position: "absolute",
        backgroundImage,
        backgroundSize: "cover",
        left: 0,
      }}
    >
      <Stack justifyContent="space-between" height="100%">
        <Box sx={{ height: 64 }}>
          <IconButton
            sx={{
              alignSelf: "flex-start",
              height: 30,
              marginTop: 2,
              marginLeft: 1.5,
              marginBottom: 0.5,
              color: RedesignrDarkTheme.palette.primary.dark,
            }}
            onClick={handleOnBack}
            size="small"
            data-testid="backButton"
          >
            <ChevronLeft size={24} />
          </IconButton>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          px={4}
          mt={isSmallScreen ? 2.25 : "auto"}
          rowGap={1.5}
        >
          <Typography variant="ui-4xl-bold" color={RedesignrDarkTheme.palette.text.primary}>
            <Trans>What you get with Wahoo App</Trans>
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          pt={isSmallScreen ? 2.25 : 8}
          width="100%"
          px={0}
        >
          <Stack width={"100%"} gap={1.5} alignItems="flex-start" justifyContent="flex-start">
            {featuresList.map((feature, index) => (
              <Stack
                alignItems="center"
                direction="row"
                key={index}
                paddingX={4}
                paddingY={1.5}
                gap={1.5}
                width="fit-content"
                justifyContent="flex-start"
                sx={{
                  borderTopRightRadius: 42,
                  borderBottomRightRadius: 42,
                  backgroundColor: RedesignrDarkTheme.palette.background.paper,
                }}
              >
                <img src={feature.icon} alt={feature.text} style={{ height: 32, width: 32 }} />
                <Typography variant="ui-lg-medium" color={RedesignrDarkTheme.palette.text.primary}>
                  {feature.text}
                </Typography>
              </Stack>
            ))}
          </Stack>
        </Box>
        <Box p={3} width="100%" mt="auto" display="flex" justifyContent="center">
          <Button
            variant="contained"
            size="large"
            fullWidth
            onClick={handleCompleteOnboarding}
            sx={{
              backgroundColor: `${RedesignrDarkTheme.palette.primary.main}`,
              color: `${RedesignrDarkTheme.palette.primary.contrastText}`,
              "&:hover": {
                backgroundColor: `${RedesignrDarkTheme.palette.primary.dark}`,
              },
              maxWidth: "sm",
            }}
          >
            <Trans>Let&apos;s begin</Trans>
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};

export default AthleteOnboardingFeatureScreen;
