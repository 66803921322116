import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { formatISO } from "date-fns";
import {
  AnswerListType,
  AnswerRangeType,
  FourDPQuizType,
  ImperialMetricDisplayPreference,
  QuestionType,
  SelectionMechanism,
  UserType,
} from "@WahooFitness/cloud-client-types";
import DateOfBirthInput from "../User/AboutYou/DateOfBirthInput";
import { OnboardingWeightInput } from "../AthleteOnboarding/StepComponents/OnboardingWeightInput";
import { OnboardingHeightInput } from "../AthleteOnboarding/StepComponents/OnboardingHeightInput";
import { StepContent } from "../AthleteOnboarding/CustomStepper";
import useAboutYou from "../User/AboutYou/useAboutYou";
import NumericEntry from "./NumericEntry/NumericEntry";
import SingleSelect from "./SingleSelect/SingleSelect";
import MultipleSelect from "./MultiSelect/MultipleSelect";
import Ranking from "./Ranking/Ranking";
import useFourDPQuizClient from "./useFourDPQuizClient";

export type Responses = Record<string, Array<string | Date | number | null>>;

function useFourDPQuiz(user: UserType) {
  const navigate = useNavigate();
  const { updateUserField } = useAboutYou(user);
  const [activeStep, setActiveStep] = useState(0);
  const [currentStepValid, setCurrentStepValid] = useState(true);
  const [currentStepHasValue, setCurrentStepHasValue] = useState(false);
  const [userUnitPreference, setUserUnitPreference] = useState(
    user.display_preference?.speed_distance ?? ImperialMetricDisplayPreference.metric
  );
  const { quizData, quizLoading, quizError } = useFourDPQuizClient();
  const [resultsLoading, setResultsLoading] = useState(false);

  /**
   * Pre-populate the user profile questions (age, height, weight, sex) with the values that are in cloud
   */
  const setInitialResponses = useCallback(
    (quizData: FourDPQuizType) => {
      return quizData.questions.reduce((acc, cur) => {
        switch (cur.answers.measurement) {
          case "dob":
            if (user.birth) {
              acc[cur.question_id] = [new Date(user.birth)];
            }
            break;
          case "weight":
            if (user.weight) {
              acc[cur.question_id] = [user.weight];
            }
            break;
          case "height":
            if (user.height) {
              acc[cur.question_id] = [user.height];
            }
            break;
          case "sex":
            if (user.gender !== undefined && user.gender !== null) {
              acc[cur.question_id] = [user.gender];
            }
            break;
          case "no_measurement":
          default:
            if (cur.answers.selection_mechanism === SelectionMechanism.exactly_all_ordered) {
              acc[cur.question_id] = (cur.answers as AnswerListType).possibility_list.map(
                (i) => i.key
              );
            }
        }
        return acc;
      }, {} as Responses);
    },
    [user.birth, user.gender, user.height, user.weight]
  );

  const [responses, setResponses] = useState<Responses>();

  useEffect(() => {
    if (!quizLoading && !quizError && quizData) {
      setResponses(setInitialResponses(quizData));
    }
  }, [quizLoading, quizError, quizData, setInitialResponses]);

  /**
   * Turns a questionnaire question into a step for the stepper
   * if the question has a `measurement` then we use a custom component
   * otherwise we look at the `selectionMechanism` to determine which component to render
   * @param question
   * @returns StepContent
   */
  const quizToStep = useCallback(
    (question: QuestionType): StepContent => {
      let Component = (
        <SingleSelect
          key={question.question_id}
          options={[]}
          initialVal={responses?.[question.question_id]?.[0] as string}
          setCurrentStepHasValue={setCurrentStepHasValue}
          setResponses={setResponses}
          questionId={question.question_id}
        />
      );
      let onContinue = () => {};
      switch (question.answers.measurement) {
        case "dob":
          Component = (
            <DateOfBirthInput
              value={
                responses?.[question.question_id][0]
                  ? new Date(responses[question.question_id][0]!)
                  : null
              }
              onChange={(value: Date | null) =>
                setResponses((prev) => {
                  if (value && prev) {
                    prev[question.question_id] = [value];
                  }
                  return prev;
                })
              }
            />
          );
          onContinue = () => {
            if (responses?.[question.question_id]) {
              updateUserField(
                formatISO(responses[question.question_id][0] as Date, { representation: "date" }),
                "birth"
              );
            }
          };
          break;
        case "weight":
          Component = (
            <OnboardingWeightInput
              userWeight={Number(responses?.[question.question_id])}
              isWeightValid={currentStepValid}
              setIsWeightValid={setCurrentStepValid}
              userUnitPreference={userUnitPreference}
              setUserUnitPreference={setUserUnitPreference}
              setUserWeight={(weight: string) =>
                setResponses((prev) => {
                  if (prev) {
                    prev[question.question_id] = [+weight];
                  }
                  return prev;
                })
              }
            />
          );
          onContinue = () => {
            updateUserField(responses?.[question.question_id][0], "weight");
            updateUserField(userUnitPreference, "metric_weight");
          };
          break;
        case "height":
          Component = (
            <OnboardingHeightInput
              setUserHeight={(height: string | null) =>
                setResponses((prev) => {
                  if (height && prev) {
                    prev[question.question_id] = [+height];
                  }
                  return prev;
                })
              }
              userHeight={String(responses?.[question.question_id])}
              userHeightUnit={userUnitPreference}
              setUserHeightUnit={setUserUnitPreference}
              setIsHeightValid={setCurrentStepValid}
            />
          );
          onContinue = () => {
            updateUserField(responses?.[question.question_id][0], "height");
            updateUserField(userUnitPreference, "metric_speed_distance");
          };
          break;
        case "sex":
          Component = (
            <SingleSelect
              key={question.question_id}
              initialVal={responses?.[question.question_id]?.[0] as string}
              setCurrentStepHasValue={setCurrentStepHasValue}
              options={(question.answers as AnswerListType).possibility_list}
              setResponses={setResponses}
              skipText={question.answers.skip_answer_text}
              questionId={question.question_id}
              center
            />
          );
          onContinue = () => {
            updateUserField(responses?.[question.question_id][0], "gender");
          };
          break;
        case "no_measurement":
        default:
          if (question.answers.selection_mechanism === SelectionMechanism.numeric_entry) {
            const possibilities = (question.answers as AnswerRangeType).possibility_range;
            Component = (
              <NumericEntry
                key={question.question_id}
                min={possibilities.start}
                max={possibilities.stop}
                skipText={question.answers.skip_answer_text}
                initialVal={responses?.[question.question_id]?.[0] as number}
                setCurrentStepValid={setCurrentStepValid}
                setCurrentStepHasValue={setCurrentStepHasValue}
                setResponses={setResponses}
                questionId={question.question_id}
              />
            );
          } else if (question.answers.selection_mechanism === SelectionMechanism.exactly_one) {
            const possibilities = (question.answers as AnswerListType).possibility_list;
            Component = (
              <SingleSelect
                key={question.question_id}
                initialVal={responses?.[question.question_id]?.[0] as string}
                setCurrentStepHasValue={setCurrentStepHasValue}
                options={possibilities}
                setResponses={setResponses}
                skipText={question.answers.skip_answer_text}
                questionId={question.question_id}
              />
            );
          } else if (question.answers.selection_mechanism === SelectionMechanism.at_least_one) {
            const possibilities = (question.answers as AnswerListType).possibility_list;
            Component = (
              <MultipleSelect
                key={question.question_id}
                initialVal={responses?.[question.question_id] as Array<string>}
                setCurrentStepHasValue={setCurrentStepHasValue}
                options={possibilities}
                setResponses={setResponses}
                skipText={question.answers.skip_answer_text}
                questionId={question.question_id}
              />
            );
          } else if (
            question.answers.selection_mechanism === SelectionMechanism.exactly_all_ordered
          ) {
            const possibilities = (question.answers as AnswerListType).possibility_list;
            Component = (
              <Ranking
                key={question.question_id}
                initialVal={responses?.[question.question_id] as Array<string>}
                options={possibilities}
                setResponses={setResponses}
                skipText={question.answers.skip_answer_text}
                questionId={question.question_id}
              />
            );
          }
      }
      return {
        stepId: question.question_id,
        stepName: question.title,
        headline: question.title,
        subheader: question.subtext,
        component: Component,
        onContinue,
        disabled: !responses?.[question.question_id] || !currentStepValid,
      };
    },
    [currentStepValid, responses, updateUserField, userUnitPreference]
  );

  const steps = useMemo(() => {
    if (quizData && responses) {
      return quizData.questions.map(quizToStep);
    }
  }, [quizData, quizToStep, responses]);

  const handleNext = useCallback(() => {
    // log4DPQuizEvent("continue");
    if (steps?.[activeStep].onContinue) {
      steps[activeStep].onContinue!();
    }
    if (steps?.length && activeStep === steps.length - 1) {
      setResultsLoading(true);
      setTimeout(() => {
        setResultsLoading(false);
        navigate("/4dp-quiz-results");
      }, 3000);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  }, [activeStep, navigate, steps]);

  const handleBack = useCallback(() => {
    // log4DPQuizEvent("back");
    if (activeStep === 0) {
      navigate(-1);
    } else {
      setActiveStep((prevActiveStep) => Math.max(prevActiveStep - 1, 0));
    }
  }, [activeStep, navigate]);

  useEffect(() => {
    setCurrentStepHasValue(!!responses?.[activeStep + 1]);
  }, [responses, activeStep]);

  return {
    activeStep,
    steps,
    handleNext,
    handleBack,
    currentStepHasValue,
    currentStepValid,
    responses,
    resultsLoading,
    quizLoading,
    quizError,
  };
}

export default useFourDPQuiz;
