import { Dispatch, SetStateAction } from "react";
import {
  Box,
  FormControlLabel,
  Checkbox,
  FormControl,
  FormGroup,
  Typography,
  useTheme,
} from "@mui/material";
import {
  RedesignrFormControlLabel,
  RedesignrFormControlLabelContent,
  RedesignrCheckbox,
} from "@WahooFitness/redesignr";
import { Stack } from "@mui/system";
import { Trans } from "@lingui/macro";
import useMultipleSelect from "./useMultipleSelect";
import { Responses } from "../useFourDPQuiz";
import { AnswerListPossibility } from "@WahooFitness/cloud-client-types/FourDPQuizType";

interface Props {
  initialVal?: Array<string>;
  options: Array<AnswerListPossibility>;
  skipText?: string;
  setCurrentStepHasValue: (val: boolean) => void;
  setResponses: Dispatch<SetStateAction<Responses | undefined>>;
  questionId: number;
}
function MultipleSelect({
  initialVal,
  setCurrentStepHasValue,
  setResponses,
  questionId,
  skipText,
  options,
}: Props) {
  const { val, onChange, skipChecked, handleSkipToggle } = useMultipleSelect({
    initialVal,
    setCurrentStepHasValue,
    setResponses,
    questionId,
  });

  const { palette } = useTheme();

  return (
    <Box display="flex" flexDirection="column" width="100%" gap={2}>
      <Typography color={skipChecked ? palette.text.disabled : "primary"}>
        <Trans>Select all that apply.</Trans>
      </Typography>
      <FormControl component="fieldset" variant="standard" disabled={skipChecked}>
        <FormGroup>
          <Stack direction="column" flexWrap="wrap" gap={1.5}>
            {options.map((option) => (
              <RedesignrFormControlLabel
                key={option.key}
                value={option.key}
                control={
                  <RedesignrCheckbox
                    checked={val?.includes(option.key)}
                    onChange={onChange}
                    name={option.text.title}
                  />
                }
                label={
                  <RedesignrFormControlLabelContent
                    align="left"
                    label={option.text.title}
                    secondary={option.text.subtext}
                  />
                }
              />
            ))}
          </Stack>
        </FormGroup>
      </FormControl>
      {skipText && (
        <Box mx={2}>
          <FormControlLabel
            control={<Checkbox checked={skipChecked} onChange={handleSkipToggle} />}
            label={skipText}
          />
        </Box>
      )}
    </Box>
  );
}

export default MultipleSelect;
