import { t } from "@lingui/macro";
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Stack,
  useTheme,
  Box,
} from "@mui/material";
import { ChevronDownIcon } from "@WahooFitness/redesignr";
import { useState } from "react";

type FitnessProfileAccordionProps = {
  type: "Strength" | "Weakness";
  name?: string;
  description?: string;
  icon: string;
  isSmallScreen: boolean;
};
const FitnessProfileAccordion = ({
  type,
  name,
  description,
  icon,
  isSmallScreen,
}: FitnessProfileAccordionProps) => {
  const { palette } = useTheme();

  const [isExpanded, setIsExpanded] = useState(!isSmallScreen);

  const handleChangeExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Box
      display="flex"
      flex={1}
      width="100%"
      alignSelf={{ xs: "center", sm: isExpanded ? "stretch" : "flex-start" }}
    >
      <Accordion
        sx={{
          width: "100%",
          backgroundColor: palette.background.paperElevation4,
          px: 0,
          mx: isSmallScreen ? 2 : 0,
          borderRadius: 2,
          "&.Mui-expanded": {
            my: 0,
            mx: isSmallScreen ? 2 : 0,
          },
          ":last-of-type": {
            borderBottom: `1px solid ${palette.divider}`,
            borderRadius: 2,
          },
        }}
        variant="outlined"
        expanded={isExpanded}
        onChange={handleChangeExpanded}
        disableGutters
      >
        <AccordionSummary expandIcon={<ChevronDownIcon />}>
          <img src={icon} alt={name} style={{ height: 44, width: 44 }} />
          <Stack ml={1.5}>
            <Typography variant="prose-sm" color="text.secondary">
              {type === "Strength" ? t`Strength` : t`Weakness`}
            </Typography>
            <Typography variant="prose-md">{name}</Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>{description}</Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default FitnessProfileAccordion;
