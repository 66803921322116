import { SetNavHeaderType } from "@/components/Header";
import { createContext, useState } from "react";

interface IHeaderContextProps {
  setSetNavHeader: (setNavHeader: SetNavHeaderType) => void;
  setNavHeader: SetNavHeaderType;
}

export const HeaderContext = createContext<IHeaderContextProps | undefined>(undefined);

export const HeaderContextProvider = ({ children }: any) => {
  const [setNavHeader, setSetNavHeader] = useState<SetNavHeaderType>(() => () => {});
  return (
    <HeaderContext.Provider
      value={{
        setNavHeader,
        setSetNavHeader,
      }}
    >
      {children}
    </HeaderContext.Provider>
  );
};
