import { useHeaderContext } from "@/hooks/useHeaderContext";
import { t, Trans } from "@lingui/macro";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLiveTrack } from "./useLiveTrack";
import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import {
  BlueSwitch,
  DeleteIcon,
  MenuList,
  MenuListItemType,
  MenuListItemVariant,
} from "@WahooFitness/redesignr";
import { postEvent, PullToRefresh, useDialogContext } from "@WahooFitness/wahoo-offline-mfe";
import { AddLiveTrackContact } from "./AddLiveTrackContact";

const LiveTrackContacts = () => {
  const { palette } = useTheme();
  const { setNavHeader } = useHeaderContext();
  const { setDialog, handleClose: handleDialogClose } = useDialogContext();
  const [addContactOpen, setAddContactOpen] = useState(false);
  const { liveTrackContacts, reloadLiveTrackContacts, toggleContactEnabled, deleteContact } =
    useLiveTrack();

  const handleAddContact = useCallback(() => {
    postEvent("getNativeContacts", {});
    setAddContactOpen(true);
  }, [setAddContactOpen]);

  const handleCloseAddContact = useCallback(() => {
    setAddContactOpen(false);
  }, [setAddContactOpen]);

  useEffect(() => {
    setNavHeader({
      title: t`Live Track auto-share`,
      headerAction: (
        <Button onClick={handleAddContact}>
          <Trans>Add</Trans>
        </Button>
      ),
    });
  }, [handleAddContact, setNavHeader]);

  const handleConfirmDelete = useCallback(
    (contactId: number) => {
      setDialog({
        open: true,
        title: t`Remove auto-share email?`,
        body: t`Are you sure you want to remove this auto-share email? This cannot be undone.`,
        actions: [
          { text: t`Cancel` },
          {
            text: t`Remove`,
            color: "error",
            action: () => {
              deleteContact(contactId);
              handleDialogClose();
            },
          },
        ],
      });
    },
    [deleteContact, handleDialogClose, setDialog]
  );

  const contactsList = useMemo((): MenuListItemType[] => {
    return (liveTrackContacts ?? []).map((contact) => ({
      id: `${contact.id}`,
      content: (
        <Typography overflow="hidden" textOverflow="ellipsis">
          {contact.email}
        </Typography>
      ),
      variant: MenuListItemVariant.NoAction,
      actionComponent: (
        <>
          <IconButton onClick={() => toggleContactEnabled(contact.id)}>
            <BlueSwitch checked={contact.enabled} />
          </IconButton>
          <IconButton sx={{ marginLeft: 1 }} onClick={() => handleConfirmDelete(contact.id)}>
            <DeleteIcon color="error" />
          </IconButton>
        </>
      ),
    }));
  }, [handleConfirmDelete, liveTrackContacts, toggleContactEnabled]);

  return (
    <>
      <PullToRefresh onRefresh={reloadLiveTrackContacts} />
      <Box m={2} display="flex" flexDirection="column" gap={2}>
        <Typography color={palette.text.primary} variant="prose-sm" align="center">
          <Trans>
            When enabled, your Live Track URL are automatically shared with selected contacts as
            soon as you start recording.
          </Trans>
        </Typography>
        {contactsList.length === 0 && (
          <Box m={3} display="flex" flexDirection="column" gap={2} alignItems="center">
            <Typography color={palette.text.primary} variant="prose-sm" align="center">
              <Trans>No recipient added</Trans>
            </Typography>
            <Button size="large" variant="contained" color="primary" onClick={handleAddContact}>
              <Trans>Add recipient</Trans>
            </Button>
          </Box>
        )}
        {contactsList.length > 0 && <MenuList disableGutters listItems={contactsList} />}
      </Box>
      <AddLiveTrackContact open={addContactOpen} handleClose={handleCloseAddContact} />
    </>
  );
};

export { LiveTrackContacts };
