import { useHeaderContext } from "@/hooks/useHeaderContext";
import NavHeader from "./NavHeader";
import { useState, useEffect, useCallback } from "react";

export type SetNavHeaderType = ({
  title,
  backAction,
  headerAction,
  hasOwnHeader,
  showCloseIcon,
  backText,
  maxTitleWidths,
  isTransparent,
  isFixed,
  disableBackButton,
  disableBoxShadow,
}: {
  title?: string | JSX.Element;
  backAction?: () => void;
  headerAction?: React.ReactElement;
  hasOwnHeader?: boolean;
  showCloseIcon?: boolean;
  backText?: string;
  maxTitleWidths?: Array<{ mediaQuery: string; maxWidth: string | number }>;
  isTransparent?: boolean;
  isFixed?: boolean;
  disableBackButton?: boolean;
  disableBoxShadow?: boolean;
}) => void;

export default function Header() {
  const { setSetNavHeader } = useHeaderContext();

  const [navTitle, setNavTitle] = useState<string | JSX.Element | undefined>(undefined);
  const [backAction, setBackAction] = useState<() => void>();
  const [headerAction, setHeaderAction] = useState<React.ReactElement | undefined>(undefined);
  const [showCloseIcon, setShowCloseIcon] = useState<boolean | undefined>(false);
  const [backText, setBackText] = useState<string | undefined>(undefined);
  const [maxTitleWidths, setMaxTitleWidths] = useState<
    Array<{ mediaQuery: string; maxWidth: string | number }> | undefined
  >(undefined);
  const [isTransparent, setIsTransparent] = useState<boolean | undefined>(false);
  const [isFixed, setIsFixed] = useState<boolean | undefined>(false);
  const [disableBackButton, setDisableBackButton] = useState<boolean | undefined>(false);
  const [disableBoxShadow, setDisableBoxShadow] = useState<boolean | undefined>(false);

  const setNavHeader = useCallback<SetNavHeaderType>(
    ({
      title,
      backAction,
      headerAction,
      hasOwnHeader,
      showCloseIcon,
      backText,
      maxTitleWidths,
      isTransparent,
      isFixed,
      disableBackButton,
      disableBoxShadow,
    }) => {
      if (!hasOwnHeader) {
        setNavTitle(title);
        setBackAction(() => backAction);
        setHeaderAction(headerAction);
        setShowCloseIcon(showCloseIcon);
        setBackText(backText);
        setMaxTitleWidths(maxTitleWidths);
        setIsTransparent(isTransparent);
        setIsFixed(isFixed);
        setDisableBackButton(disableBackButton);
        setDisableBoxShadow(disableBoxShadow);
      }
    },
    []
  );

  useEffect(() => {
    setSetNavHeader(() => setNavHeader);
  }, [setSetNavHeader, setNavHeader]);

  if (navTitle === undefined) {
    return null;
  }
  return (
    <NavHeader
      title={navTitle}
      backAction={backAction}
      headerAction={headerAction}
      closeIcon={showCloseIcon}
      backText={backText}
      data-testid="navHeader"
      maxTitleWidths={maxTitleWidths}
      isTransparent={isTransparent}
      isFixed={isFixed}
      disableBackButton={disableBackButton}
      disableBoxShadow={disableBoxShadow}
    />
  );
}
