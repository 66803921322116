import { WarningAlt } from "@carbon/icons-react";
import { Box, Typography } from "@mui/material";
import WarmingUp from "./WarmUp";
import { Trans } from "@lingui/macro";

const PageLoadError = ({ isLoading = false }: { isLoading?: boolean }) => {
  return (
    <Box display="flex" flexDirection="column" height="100%" mx={2}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100%"
        gap={1}
      >
        <Typography color={"warning.main"}>
          <WarningAlt height={30} width={30} />
        </Typography>
        <Typography variant="prose-md" align="center">
          <Trans>Sorry, something went wrong. But you are going to be ok!</Trans>
        </Typography>
        {isLoading && <WarmingUp noTopSpacing />}
      </Box>
    </Box>
  );
};

export default PageLoadError;
