import { Box } from "@mui/system";
import { useEffect, useMemo } from "react";
import { UserType } from "@WahooFitness/cloud-client-types";
import { useHeaderContext } from "@/hooks/useHeaderContext";
import { Button, Typography, useTheme } from "@mui/material";
import { t, Trans } from "@lingui/macro";
import useFourDPQuiz from "./useFourDPQuiz";
import CustomStepper from "../AthleteOnboarding/CustomStepper";
import ErrorBoundary from "../ErrorBoundary";
import Loader from "./Results/Loader";

interface Props {
  user: UserType;
}

function FourDPQuiz({ user }: Props) {
  const { setNavHeader } = useHeaderContext();
  const { palette } = useTheme();

  const {
    activeStep,
    steps,
    handleNext,
    handleBack,
    currentStepHasValue,
    currentStepValid,
    resultsLoading,
    quizLoading,
    quizError,
  } = useFourDPQuiz(user);

  const headerProps = useMemo(
    () => ({
      title: "",
      backAction: handleBack,
      showCloseIcon: activeStep === 0,
      disableBoxShadow: true,
    }),
    [activeStep, handleBack]
  );

  useEffect(() => {
    setNavHeader(headerProps);
  }, [headerProps, setNavHeader]);

  if (!steps) {
    return;
  }

  return (
    <ErrorBoundary
      isLoading={quizLoading || resultsLoading}
      error={quizError}
      loadingComponent={
        <Loader
          message={quizLoading ? t`Organizing the minions` : t`Calculating your results...`}
        />
      }
    >
      <Box height="100%" sx={{ boxShadow: `0 0 0 100vmax ${palette.background.paperElevation4}` }}>
        <>
          <Box
            display="flex"
            position="absolute"
            left={0}
            pl={2}
            py={1}
            sx={{ backgroundColor: palette.background.paperElevation4 }}
          >
            <CustomStepper steps={steps} activeStep={activeStep} />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            width="100%"
            height="100%"
            sx={{ backgroundColor: palette.background.paperElevation4 }}
          >
            <Box display="flex" flexDirection="column" px={2} pt={5} rowGap={1.5}>
              <Typography variant="ui-4xl-bold">{steps[activeStep].headline}</Typography>
              <Typography variant="prose-base" color="text.secondary">
                {steps[activeStep].subheader}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="center" pt={2} width="100%" px={2}>
              {steps[activeStep].component}
            </Box>
            <Box p={3} width="100%" mt={1} maxWidth="sm">
              <Button
                variant="contained"
                size="large"
                fullWidth
                onClick={handleNext}
                disabled={!currentStepHasValue || !currentStepValid}
              >
                <Trans>Continue</Trans>
              </Button>
            </Box>
          </Box>
        </>
      </Box>
    </ErrorBoundary>
  );
}

export default FourDPQuiz;
