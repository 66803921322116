import { convertPoundsToKilograms, convertKilogramsToPounds } from "@/services/convertUnits";
import { truncateToTwoDecimalPlaces } from "@/services/truncateNumbers";
import { t } from "@lingui/macro";
import { ImperialMetricDisplayPreference } from "@WahooFitness/cloud-client-types";
import {
  ChangeEventHandler,
  FocusEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import useDebounce from "./useDebounce";

type Props = {
  initialWeight: number | null;
  unitPreference: ImperialMetricDisplayPreference;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  setUnitPreference?: (unit: ImperialMetricDisplayPreference) => void;
  isWeightValid: boolean;
  setIsWeightValid: (value: boolean) => void;
};
export const useWeight = ({
  initialWeight,
  unitPreference,
  onBlur,
  setUnitPreference,
  isWeightValid,
  setIsWeightValid,
}: Props) => {
  const [currentValue, setCurrentValue] = useState<string | null>("");

  const maxWeight = useMemo(
    () => (unitPreference === ImperialMetricDisplayPreference.metric ? 255 : 562),
    [unitPreference]
  );

  const minWeight = useMemo(
    () => (unitPreference === ImperialMetricDisplayPreference.metric ? 20 : 44),
    [unitPreference]
  );
  const debouncedWeight = useDebounce(currentValue, 500);

  const checkWeight = useCallback(
    (value: string | null) => {
      return !!value && !Number.isNaN(+value) && +value >= minWeight && +value <= maxWeight;
    },
    [maxWeight, minWeight]
  );

  const errorMessage = useMemo(() => {
    // check both the debounced value and the currentValue to avoid the error
    // showing momentarily when weight becomes valid, but debounced value hasn't populated yet
    if (!!debouncedWeight && !isWeightValid && !checkWeight(currentValue)) {
      return t`Enter a value between ${minWeight} and ${maxWeight}`;
    }
  }, [checkWeight, currentValue, debouncedWeight, isWeightValid, maxWeight, minWeight]);

  useEffect(() => {
    setIsWeightValid(checkWeight(debouncedWeight));
  }, [checkWeight, debouncedWeight, maxWeight, minWeight, setIsWeightValid]);

  const handleChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = useCallback(
    (event) => {
      setCurrentValue(event.target.value);
    },
    []
  );

  const handleBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> = useCallback(
    (event) => {
      if (!checkWeight(currentValue) && initialWeight) {
        const previousWeight =
          unitPreference === ImperialMetricDisplayPreference.metric
            ? truncateToTwoDecimalPlaces(initialWeight)
            : truncateToTwoDecimalPlaces(convertKilogramsToPounds(initialWeight));
        setCurrentValue(previousWeight);
        return;
      }
      // for onboarding, we may not have an initial weight
      if (!checkWeight(currentValue) && !initialWeight) {
        setCurrentValue("");
        return;
      }

      const newValue = truncateToTwoDecimalPlaces(event.target.value);
      if (newValue !== event.target.value) {
        setCurrentValue(newValue);
      }
      if (onBlur) {
        onBlur?.({
          ...event,
          target: { ...event.target, value: newValue },
        });
      }
    },
    [checkWeight, currentValue, initialWeight, onBlur, unitPreference]
  );

  const handleUnitPreferenceChange = (
    _event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: string
  ) => {
    const newValue =
      value === "metric"
        ? ImperialMetricDisplayPreference.metric
        : ImperialMetricDisplayPreference.imperial;
    if (unitPreference !== newValue && setUnitPreference) {
      setUnitPreference(newValue);

      if (currentValue) {
        const newValue =
          value === "metric"
            ? truncateToTwoDecimalPlaces(convertPoundsToKilograms(parseFloat(currentValue)))
            : truncateToTwoDecimalPlaces(convertKilogramsToPounds(parseFloat(currentValue)));
        setCurrentValue(newValue);
      }
    }
  };

  return {
    errorMessage,
    maxWeight,
    handleChange,
    currentValue,
    setCurrentValue,
    handleBlur,
    handleUnitPreferenceChange,
  };
};
