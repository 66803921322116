import { useState, useMemo, useCallback } from "react";

import { ImperialMetricDisplayPreference, UserType } from "@WahooFitness/cloud-client-types";
import { t } from "@lingui/macro";
import { formatISO } from "date-fns";
import { useAnalyticsContext, postRoute } from "@WahooFitness/wahoo-offline-mfe";
import Sex from "@/services/types/Sex";
import useAboutYou from "../User/AboutYou/useAboutYou";
import DateOfBirthInput from "../User/AboutYou/DateOfBirthInput";
import useTrainingPreferences from "../TrainingPreferences/useTrainingPreferences";
import { OnboardingHeightInput } from "./StepComponents/OnboardingHeightInput";
import SexSelect from "./StepComponents/SexSelect";
import SportSelect from "./StepComponents/SportSelect";
import { OnboardingWeightInput } from "./StepComponents/OnboardingWeightInput";
import { StepContent } from "./CustomStepper";

export const useAthleteOnboarding = (user: UserType) => {
  const { logEvent } = useAnalyticsContext();
  const [activeStep, setActiveStep] = useState(0);
  const [showFeatureScreen, setShowFeatureScreen] = useState(false);
  const { updateUserField } = useAboutYou(user);
  const { selectedSportOptions, setSelectedSportOptions, setTrainingPreferences } =
    useTrainingPreferences(false);
  const [userSex, setUserSex] = useState(user.gender as Sex);
  const [userDateOfBirth, setUserDateOfBirth] = useState<Date | null>(
    user.birth ? new Date(user.birth + "T00:00:00") : null
  );
  const initialHeight = user.height;
  const [userHeight, setUserHeight] = useState<string | null>(initialHeight);
  const [isHeightValid, setIsHeightValid] = useState<boolean>(false);
  const [isWeightValid, setIsWeightValid] = useState<boolean>(false);
  const [userUnitPreference, setUserUnitPreference] = useState(
    user.display_preference?.speed_distance ?? ImperialMetricDisplayPreference.metric
  );

  const [userWeight, setUserWeight] = useState<string | null>(user.weight);

  const steps: StepContent[] = useMemo(
    () => [
      {
        stepId: 1,
        stepName: "birthday",
        headline: t`When is your birthday?`,
        subheader: t`This information helps build your athlete profile, ensuring you get precise and personalized performance metrics.`,
        component: (
          <DateOfBirthInput
            value={userDateOfBirth}
            onChange={setUserDateOfBirth}
            textFieldOnlyPicker
          />
        ),
        skippable: false,
        onContinue: () => {
          if (userDateOfBirth) {
            updateUserField(formatISO(userDateOfBirth, { representation: "date" }), "birth");
          }
        },
        disabled: userDateOfBirth === null,
      },
      {
        stepId: 2,
        stepName: "sex",
        headline: t`What is your sex?`,
        subheader: t`Your biological sex can be a good indicator of your power output abilities, and is used to help inform your athlete profile and other defaults.`,
        component: <SexSelect userSex={userSex} setUserSex={setUserSex} />,
        skippable: false,
        onContinue: () => {
          updateUserField(userSex, "gender");
        },
        disabled: userSex === null,
      },
      {
        stepId: 3,
        stepName: "height",
        headline: t`What is your height?`,
        subheader: t`This information helps build your athlete profile, ensuring you get precise and personalized performance metrics.`,
        component: (
          <OnboardingHeightInput
            setUserHeight={setUserHeight}
            userHeight={initialHeight}
            userHeightUnit={userUnitPreference}
            setUserHeightUnit={setUserUnitPreference}
            setIsHeightValid={setIsHeightValid}
          />
        ),
        skippable: true,
        onContinue: () => {
          updateUserField(userHeight, "height");
          updateUserField(userUnitPreference, "metric_speed_distance");
        },
        disabled: !isHeightValid,
      },
      {
        stepId: 4,
        stepName: "weight",
        headline: t`What is your weight?`,
        subheader: t`This information helps build your athlete profile, ensuring you get precise and personalized performance metrics.`,
        component: (
          <OnboardingWeightInput
            setUserWeight={setUserWeight}
            userWeight={Number(userWeight)}
            userUnitPreference={userUnitPreference}
            setUserUnitPreference={setUserUnitPreference}
            isWeightValid={isWeightValid}
            setIsWeightValid={setIsWeightValid}
          />
        ),
        skippable: true,
        onContinue: () => {
          updateUserField(userWeight, "weight");
          updateUserField(userUnitPreference, "metric_weight");
        },
        disabled: !isWeightValid,
      },
      {
        stepId: 5,
        stepName: "sportPreference",
        headline: t`What sports are you interested in?`,
        subheader: t`Select all that applies.`,
        component: (
          <SportSelect
            selectedSportOptions={selectedSportOptions}
            setSelectedSportOptions={setSelectedSportOptions}
          />
        ),
        onContinue: () => {
          setTrainingPreferences(selectedSportOptions);
        },
        skippable: false,
        disabled: selectedSportOptions.size === 0,
      },
    ],
    [
      userDateOfBirth,
      userSex,
      initialHeight,
      userUnitPreference,
      isHeightValid,
      userWeight,
      isWeightValid,
      selectedSportOptions,
      setSelectedSportOptions,
      updateUserField,
      userHeight,
      setTrainingPreferences,
    ]
  );

  const logAccountCreationEvent = useCallback(
    (event_type: "continue" | "skip" | "back") => {
      logEvent("account_creation", {
        source_page_name: "athlete_onboarding",
        step_name: showFeatureScreen ? "appFeatures" : steps[activeStep].stepName,
        step_ordering: showFeatureScreen ? steps.length + 1 : activeStep + 1,
        user_id: user.id,
        event_type,
      });
    },
    [activeStep, logEvent, showFeatureScreen, steps, user.id]
  );

  const handleNext = useCallback(() => {
    logAccountCreationEvent("continue");
    if (steps[activeStep].onContinue) {
      steps[activeStep].onContinue!();
    }
    if (showFeatureScreen) {
      postRoute("onboardingCompleted");
    } else if (activeStep === steps.length - 1) {
      setShowFeatureScreen(true);
    } else setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }, [activeStep, logAccountCreationEvent, showFeatureScreen, steps]);

  const handleSkip = useCallback(() => {
    logAccountCreationEvent("skip");
    if (showFeatureScreen) {
      postRoute("onboardingCompleted");
    } else if (activeStep === steps.length - 1) {
      setShowFeatureScreen(true);
    } else setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }, [activeStep, logAccountCreationEvent, showFeatureScreen, steps.length]);

  const handleBack = useCallback(() => {
    logAccountCreationEvent("back");
    if (showFeatureScreen) {
      setShowFeatureScreen(false);
    } else {
      setActiveStep((prevActiveStep) => Math.max(prevActiveStep - 1, 0));
    }
  }, [logAccountCreationEvent, showFeatureScreen]);

  return {
    activeStep,
    steps,
    handleNext,
    handleBack,
    handleSkip,
    showFeatureScreen,
    selectedSportOptions,
    setShowFeatureScreen,
  };
};

export default useAthleteOnboarding;
