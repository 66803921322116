import { useRef } from "react";
import { RiderTypeGraph } from "./RiderTypeGraph";
import { Box } from "@mui/system";
import useRiderType from "../useRiderType";
import { FourDpChip } from "./FourDpChip";
import { Typography } from "@mui/material";
import { Trans } from "@lingui/macro";

export const RiderTypeGraphWrapper = () => {
  const iconRef = useRef<SVGImageElement>(null);
  const arcRef = useRef<SVGPathElement>(null);
  const { indoorRiderTypeIconUrl, indoorPowerNM, indoorPowerAC, indoorPowerMAP, indoorPowerFTP } =
    useRiderType();

  // TODO: Update graphValues and status. They are hard coded as example values. We don't get these from cloud yet.
  const values = {
    Nm: { value: indoorPowerNM, graphValue: 0.65, status: "valid" },
    Ac: { value: indoorPowerAC, graphValue: 0.45, status: "valid" },
    Map: { value: indoorPowerMAP, graphValue: 0.25, status: "valid" },
    Ftp: { value: indoorPowerFTP, graphValue: 0, status: "valid" },
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      px={4}
      pb={2}
    >
      <Box maxWidth="350px">
        <Box display="flex" alignItems="center" justifyContent="center">
          <FourDpChip fourDpType="Ac" value={values["Ac"]?.value || undefined} />
        </Box>
        <Box width="100%" px={2}>
          <RiderTypeGraph
            values={values}
            riderIconUrl={indoorRiderTypeIconUrl}
            iconRef={iconRef}
            arcRef={arcRef}
          />
        </Box>
        <Box mt={0.5} display="flex" justifyContent="space-between">
          <FourDpChip fourDpType="Nm" value={values["Nm"]?.value || undefined} />
          <FourDpChip fourDpType="Map" value={values["Map"]?.value || undefined} />
        </Box>
        <Box display="flex" justifyContent="center">
          <FourDpChip fourDpType="Ftp" value={values["Ftp"]?.value || undefined} />
        </Box>
        <Box mt={2}>
          <Typography align="center" variant="ui-xs" component="div">
            <Trans>
              Your 4DP Profile: The further each wedge extends toward the edge of the graph, the
              stronger you are in this area relative to your FTP.
            </Trans>
          </Typography>{" "}
        </Box>
      </Box>
    </Box>
  );
};
