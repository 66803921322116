import { useEffect, useRef, useState } from "react";

export function useDebounce<T>(value: T, delayMs?: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  const timer = useRef<number>();

  useEffect(() => {
    if (!timer.current) {
      timer.current = window.setTimeout(() => {
        setDebouncedValue(value);
        timer.current = undefined;
      }, delayMs);
    } else {
      window.clearTimeout(timer.current);
      timer.current = window.setTimeout(() => {
        setDebouncedValue(value);
        timer.current = undefined;
      }, delayMs);
    }
  }, [value, delayMs]);

  return debouncedValue;
}

export default useDebounce;
