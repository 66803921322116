import { useState, useCallback, Dispatch, SetStateAction } from "react";
import { DropResult } from "react-beautiful-dnd";
import { Responses } from "../useFourDPQuiz";
import { AnswerListPossibility } from "@WahooFitness/cloud-client-types";

interface Props {
  initialVal?: Array<string>;
  options: Array<AnswerListPossibility>;
  setResponses: Dispatch<SetStateAction<Responses | undefined>>;
  questionId: number;
}

function useRanking({ initialVal, options, setResponses, questionId }: Props) {
  function orderOptions(valuesList: Array<string>) {
    return valuesList.map(
      (value) => options.find((option) => option.key === value) as AnswerListPossibility
    );
  }
  // state property to hold the values to show for the rankItem
  const [orderedOptions, setOrderedOptions] = useState(
    !initialVal || initialVal[0] === "skip" ? options : orderOptions(initialVal)
  );

  const [skipChecked, setSkipChecked] = useState(initialVal?.[0] === "skip");

  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (result.destination) {
        let newOrder = [...orderedOptions];
        const [draggedItem] = newOrder.splice(result.source.index, 1);
        newOrder = [
          ...newOrder.slice(0, result.destination.index),
          draggedItem,
          ...newOrder.slice(result.destination.index),
        ];
        setOrderedOptions(newOrder);
        setResponses((prev) => {
          if (prev) {
            prev[questionId] = newOrder.map((i) => i.key);
          }
          return prev;
        });
      }
    },
    [orderedOptions, questionId, setResponses]
  );

  const handleSkipToggle = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      if (ev.target.checked) {
        setSkipChecked(true);
        setResponses((prev: any) => {
          prev[questionId] = ["skip"];
          return prev;
        });
      } else {
        setSkipChecked(false);
        setResponses((prev: any) => {
          prev[questionId] = orderedOptions.map((i) => i.key);
          return prev;
        });
      }
    },
    [questionId, setResponses, orderedOptions]
  );

  return {
    onDragEnd,
    orderedOptions,
    skipChecked,
    handleSkipToggle,
  };
}

export default useRanking;
