import { SensorManagerPluginPlugin as SensorManagerPlugin } from "@WahooFitness/wsm-native";
import WSMSensorProductType from "@WahooFitness/wsm-native/dist/esm/types/product_type";
import { Capacitor, PluginListenerHandle, registerPlugin } from "@capacitor/core";
import { publish } from "@ionic/portals";
import { PropsWithChildren, createContext, useCallback, useMemo, useState } from "react";
import PeachTreeRoadRace from "./PeachtreeRoadRace.json";
import DirtySheets from "./DirtySheetz.json";
import BrokenRoute from "./BrokenRoute.json";
import { WSMRouteDetail } from "@WahooFitness/wsm-native/dist/esm/types/route";
import mockRoutes from "./routeMockData";
import { IWsmPluginProps } from "@/contexts/WsmContext";
import { WSMLiveTrackStatus } from "@WahooFitness/wsm-native/dist/esm/types/live_track_status";
import WSMSensorConnectionState from "@WahooFitness/wsm-native/dist/esm/types/connection_state";

const mockRouteFit: Record<string, WSMRouteDetail> = {
  route1: PeachTreeRoadRace as unknown as WSMRouteDetail,
  route2: DirtySheets,
  route3: PeachTreeRoadRace as unknown as WSMRouteDetail,
  route4: DirtySheets,
  route5: PeachTreeRoadRace as unknown as WSMRouteDetail,
  route6: DirtySheets,
  route7: PeachTreeRoadRace as unknown as WSMRouteDetail,
  route8: BrokenRoute as unknown as WSMRouteDetail,
};

const wsmPluginLabel = "SensorManagerPlugin";

export const WsmContext = createContext<IWsmPluginProps | undefined>(undefined);

export const WsmContextProvider = ({ children }: PropsWithChildren) => {
  const sensorManagerPlugin = useMemo(() => {
    if (!Capacitor.isPluginAvailable(wsmPluginLabel)) {
      publish({
        topic: "settingsError",
        data: JSON.stringify({ errorMessage: "WSM plugin not registered", stack: "" }),
      });
      return undefined;
    }
    return registerPlugin<SensorManagerPlugin>(wsmPluginLabel);
  }, []);
  const [hasBoltPaired, setHasBoltPaired] = useState(false);
  const [hasRoamPaired, setHasRoamPaired] = useState(false);
  const [hasAcePaired, setHasAcePaired] = useState(false);
  const checkPairedDeviceTypes = useCallback(async () => {
    const result = await sensorManagerPlugin?.getDevices();
    const devices = result?.devices;
    setHasBoltPaired(
      !!devices?.some((device) => device.productType === WSMSensorProductType.WAHOO_ELEMNT_BOLT)
    );
    setHasRoamPaired(
      !!devices?.some((device) => device.productType === WSMSensorProductType.WAHOO_ELEMNT_ROAM)
    );
    setHasAcePaired(
      !!devices?.some((device) => device.productType === WSMSensorProductType.WAHOO_ELEMNT_ACE)
    );
  }, [sensorManagerPlugin]);

  const hasElemntPaired = useMemo(
    () => hasBoltPaired || hasRoamPaired || hasAcePaired,
    [hasAcePaired, hasBoltPaired, hasRoamPaired]
  );

  const webPluginOverride = useMemo(() => {
    return {
      ...(sensorManagerPlugin || {}),
      getUserRouteList: async () => ({ routes: mockRoutes }),
      addListener: async () => {
        return null as unknown as PluginListenerHandle;
      },
      getRouteDetails: async ({ token }: { token: string }) => ({
        routeDetail: mockRouteFit[token],
      }),
      selectRoute: async ({ fitnessAppId }: { fitnessAppId: number }) => ({
        completed: fitnessAppId % 2 === 0,
      }),
      getLiveTrackStatus: async (): Promise<{
        isEnabled: boolean;
        isFeatureEnabled: boolean;
        isPermitted: boolean;
        tracking?: WSMLiveTrackStatus;
      }> => ({
        isEnabled: true,
        isFeatureEnabled: true,
        isPermitted: true,
        tracking: {
          isLocal: true,
          appToken: "test",
          workoutNum: 1,
          isCommsDown: false,
          commsDownCount: 0,
          noNetworkSec: 0,
          sendFailedCount: 0,
          sendOkCount: 0,
          slowSendSec: 0,
          totalSec: 0,
          secSinceLastSendOk: 65,
          elemnt: {
            sensorConnectionState: WSMSensorConnectionState.CONNECTED,
          },
        },
      }),
    };
  }, [sensorManagerPlugin]);

  return (
    <WsmContext.Provider
      value={{
        sensorManagerPlugin: webPluginOverride as SensorManagerPlugin,
        checkPairedDeviceTypes,
        hasElemntPaired,
        hasBoltPaired,
        hasRoamPaired,
        hasAcePaired,
      }}
    >
      {children}
    </WsmContext.Provider>
  );
};
