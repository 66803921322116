export enum FlaggedFeature {
  ElemntConfig,
  AutoShareSummary,
  PaceZones,
  ElemntMultisportPage,
  ElemntPedalsPage,
  ElemntPlannedWorkouts,
  StravaLiveSegments,
  ElemntAlertCountWarning,
  RouteToPlayr,
  CuratedRoutes,
  NewAthleteProfile,
  UpdatedPaywall,
  LiveTrackConfig,
  FOURDPQuizMinProfile,
}
