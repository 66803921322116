import { useWsmPlugin } from "@/hooks/useWsmPlugin";
import { plural, t } from "@lingui/macro";
import { useTheme } from "@mui/material";
import {
  LiveTrackContacts,
  LiveTrackLinks,
  UserShareSettings,
} from "@WahooFitness/cloud-client-ts";
import { LiveTrackLinkResponseType, UserShareSettingsType } from "@WahooFitness/cloud-client-types";
import {
  postEvent,
  useCloudContext,
  useConfigContext,
  useOfflineSWR,
  useSnackbarContext,
  useUserContext,
} from "@WahooFitness/wahoo-offline-mfe";
import WSMSensorConnectionState from "@WahooFitness/wsm-native/dist/esm/types/connection_state";
import { useCallback, useMemo } from "react";
import useSWR from "swr";

const useLiveTrack = () => {
  const { palette } = useTheme();
  const { getCloudClient } = useCloudContext();
  const { wahooToken } = useConfigContext();
  const { currentUser, mutateUser } = useUserContext();
  const { enqueueSnackbar } = useSnackbarContext();

  const liveTrackLinksClient = getCloudClient(LiveTrackLinks);
  const { data: liveTrackLinks, mutate: reloadLiveTrackLinks } = useOfflineSWR(
    ["live-track-links", wahooToken],
    () => {
      return liveTrackLinksClient.get(wahooToken);
    }
  );

  const addExpiringLink = useCallback(async (): Promise<LiveTrackLinkResponseType | null> => {
    try {
      const expireTime = new Date(Date.now() + 12 * 60 * 60 * 1000);
      const result = await liveTrackLinksClient.create(wahooToken, expireTime);
      reloadLiveTrackLinks();
      return result;
    } catch (error: any) {
      enqueueSnackbar({
        message: t`There was a problem creating your Live Track link. Check your network and try again.`,
        severity: "error",
      });
      return null;
    }
  }, [enqueueSnackbar, liveTrackLinksClient, reloadLiveTrackLinks, wahooToken]);

  const deleteLink = useCallback(
    async (linkId: number) => {
      try {
        if (!liveTrackLinks || !liveTrackLinks.some((link) => link.id === linkId)) {
          return;
        }
        await liveTrackLinksClient.delete(wahooToken, linkId);
        reloadLiveTrackLinks();
      } catch (error: any) {
        enqueueSnackbar({
          message: t`There was a problem deleting your Live Track link. Check your network and try again.`,
          severity: "error",
        });
      }
    },
    [enqueueSnackbar, liveTrackLinks, liveTrackLinksClient, reloadLiveTrackLinks, wahooToken]
  );

  const liveTrackContactsClient = getCloudClient(LiveTrackContacts);
  const { data: liveTrackContacts, mutate: reloadLiveTrackContacts } = useOfflineSWR(
    ["live-track-contacts", wahooToken],
    () => {
      return liveTrackContactsClient.get(wahooToken);
    }
  );

  const toggleContactEnabled = useCallback(
    async (contactId: number) => {
      const contact = liveTrackContacts?.find((contact) => contact.id === contactId);
      if (!contact) {
        return;
      }
      try {
        await liveTrackContactsClient.update(wahooToken, contactId, {
          email: contact.email,
          enabled: !contact.enabled,
        });
        reloadLiveTrackContacts();
      } catch (error: any) {
        enqueueSnackbar({
          message: t`There was a problem updating your auto-share contact. Check your network and try again.`,
          severity: "error",
        });
      }
    },
    [
      enqueueSnackbar,
      liveTrackContacts,
      liveTrackContactsClient,
      reloadLiveTrackContacts,
      wahooToken,
    ]
  );

  const addContact = useCallback(
    async (email: string): Promise<boolean> => {
      try {
        await liveTrackContactsClient.create(wahooToken, {
          email: email,
        });
        reloadLiveTrackContacts();
        return true;
      } catch (error) {
        enqueueSnackbar({
          message: t`There was a problem adding your auto-share contact. Check your network and try again.`,
          severity: "error",
        });
        return false;
      }
    },
    [enqueueSnackbar, liveTrackContactsClient, reloadLiveTrackContacts, wahooToken]
  );

  const deleteContact = useCallback(
    async (contactId: number) => {
      try {
        await liveTrackContactsClient.delete(wahooToken, contactId);
        reloadLiveTrackContacts();
      } catch (error: any) {
        enqueueSnackbar({
          message: t`There was a problem removing your auto-share contact. Check your network and try again.`,
          severity: "error",
        });
      }
    },
    [enqueueSnackbar, liveTrackContactsClient, reloadLiveTrackContacts, wahooToken]
  );

  const shareSettingsClient = getCloudClient(UserShareSettings);
  const updateShareSettings = useCallback(
    async (shareSettings: UserShareSettingsType) => {
      try {
        if (!currentUser) {
          return;
        }
        const result = await shareSettingsClient.update(wahooToken, currentUser.id, shareSettings);
        mutateUser({ ...currentUser, share_setting: result });
        return result;
      } catch (error: any) {
        enqueueSnackbar({
          message: t`There was a problem updating your Live Track settings. Check your network and try again.`,
          severity: "error",
        });
        return null;
      }
    },
    [currentUser, enqueueSnackbar, mutateUser, shareSettingsClient, wahooToken]
  );

  const toggleLiveTrackEnabled = useCallback(async () => {
    const newEnabled = !currentUser?.share_setting.livetrack_enabled;
    const result = await updateShareSettings({
      livetrack_enabled: !currentUser?.share_setting.livetrack_enabled,
    });
    if (!result) {
      return;
    }
    if (!newEnabled && liveTrackLinks?.length) {
      liveTrackLinks.forEach((link) => {
        deleteLink(link.id);
      });
    }
  }, [
    currentUser?.share_setting.livetrack_enabled,
    deleteLink,
    liveTrackLinks,
    updateShareSettings,
  ]);

  const toggleOptOutEnabled = useCallback(() => {
    return updateShareSettings({
      livetrack_opt_out: !currentUser?.share_setting.livetrack_opt_out,
    });
  }, [currentUser?.share_setting.livetrack_opt_out, updateShareSettings]);

  const handleShareLink = useCallback(async () => {
    const createLinkResult = await addExpiringLink();
    if (createLinkResult) {
      postEvent("shareLiveTrackLink", { liveTrackLink: createLinkResult.url });
    }
  }, [addExpiringLink]);

  const { sensorManagerPlugin } = useWsmPlugin();
  const { data: wsmLiveTrackStatus, isLoading: liveTrackStatusIsLoading } = useSWR(
    sensorManagerPlugin && currentUser?.share_setting?.livetrack_enabled && ["live_track_status"],
    () => sensorManagerPlugin?.getLiveTrackStatus(),
    { refreshInterval: 10000 }
  );

  const liveTrackStatus = useMemo((): { message: string; color?: string } => {
    if (liveTrackStatusIsLoading) {
      return { message: t`Checking status...` };
    }
    if (
      !currentUser?.share_setting?.livetrack_enabled ||
      !wsmLiveTrackStatus?.isEnabled ||
      !wsmLiveTrackStatus?.isFeatureEnabled
    ) {
      return { message: t`Disabled`, color: palette.error.main };
    } else if (!wsmLiveTrackStatus?.isPermitted) {
      return { message: t`Not permitted`, color: palette.error.main };
    } else if (wsmLiveTrackStatus?.tracking) {
      if (wsmLiveTrackStatus?.tracking.isCommsDown) {
        return { message: t`No network connection`, color: palette.error.main };
      }
      if (
        !wsmLiveTrackStatus?.tracking.isLocal &&
        wsmLiveTrackStatus?.tracking?.elemnt?.sensorConnectionState !==
          WSMSensorConnectionState.CONNECTED
      ) {
        return { message: t`ELEMNT disconnected`, color: palette.error.main };
      }
      if (wsmLiveTrackStatus?.tracking.secSinceLastSendOk < 60) {
        return { message: t`Updated less than a minute ago`, color: palette.info.main };
      }
      const minutes = Math.floor(wsmLiveTrackStatus?.tracking.secSinceLastSendOk / 60);
      return {
        message: plural(minutes, {
          one: "Updated # minute ago",
          other: "Updated # minutes ago",
        }),
        color: palette.info.main,
      };
    } else {
      return { message: t`Ready` };
    }
  }, [
    liveTrackStatusIsLoading,
    currentUser?.share_setting?.livetrack_enabled,
    wsmLiveTrackStatus?.isEnabled,
    wsmLiveTrackStatus?.isFeatureEnabled,
    wsmLiveTrackStatus?.isPermitted,
    wsmLiveTrackStatus?.tracking,
    palette.error.main,
    palette.info.main,
  ]);

  return {
    liveTrackLinks,
    reloadLiveTrackLinks,
    liveTrackContacts,
    reloadLiveTrackContacts,
    toggleLiveTrackEnabled,
    toggleOptOutEnabled,
    toggleContactEnabled,
    addContact,
    deleteContact,
    handleShareLink,
    liveTrackStatus,
  };
};

export { useLiveTrack };
