import { useState, useCallback, ChangeEvent, Dispatch, SetStateAction } from "react";
import { Responses } from "../useFourDPQuiz";

interface Props {
  initialVal?: Array<string>;
  setCurrentStepHasValue: (val: boolean) => void;
  setResponses: Dispatch<SetStateAction<Responses | undefined>>;
  questionId: number;
}

function useMultipleSelect({
  initialVal,
  setCurrentStepHasValue,
  setResponses,
  questionId,
}: Props) {
  const [val, setVal] = useState(initialVal?.[0] === "skip" ? undefined : initialVal);
  const [skipChecked, setSkipChecked] = useState(initialVal?.[0] === "skip");

  const onChange = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      const setVals = new Set(val);
      const newVal = ev.target.value;
      if (setVals?.has(newVal)) {
        setVals.delete(newVal);
      } else {
        setVals.add(newVal);
      }
      const arrayVals = Array.from(setVals);

      if (arrayVals.length) {
        setCurrentStepHasValue(true);
      } else {
        setCurrentStepHasValue(false);
      }
      setVal(arrayVals);
      setResponses((prev: any) => {
        prev[questionId] = arrayVals;
        return prev;
      });
    },
    [questionId, setCurrentStepHasValue, setResponses, val]
  );

  const handleSkipToggle = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      if (ev.target.checked) {
        setSkipChecked(true);
        setCurrentStepHasValue(true);
        setResponses((prev: any) => {
          prev[questionId] = ["skip"];
          return prev;
        });
      } else {
        setSkipChecked(false);
        setCurrentStepHasValue(!!val && val.length > 0);
        setResponses((prev: any) => {
          prev[questionId] = val;
          return prev;
        });
      }
    },
    [questionId, setCurrentStepHasValue, setResponses, val]
  );

  return {
    val,
    onChange,
    skipChecked,
    handleSkipToggle,
  };
}

export default useMultipleSelect;
