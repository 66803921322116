import { Box } from "@mui/system";
import { useCallback } from "react";
import { useUserContext } from "@WahooFitness/wahoo-offline-mfe";
import ErrorBoundary from "../ErrorBoundary";
import FourDPQuiz from "./FourDPQuiz";

function FourDPQuizContainer() {
  const { currentUser, userIsLoading, userError, mutateUser } = useUserContext();
  const handleReload = useCallback(() => {
    mutateUser();
  }, [mutateUser]);

  return (
    <ErrorBoundary error={userError} isLoading={userIsLoading} onReload={handleReload}>
      <Box display="flex" flexDirection="column" width="100%" height="100%">
        {currentUser && <FourDPQuiz user={currentUser} />}
      </Box>
    </ErrorBoundary>
  );
}

export default FourDPQuizContainer;
