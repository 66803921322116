import React, { useCallback, useMemo, useState } from "react";
import { useDialogContext } from "@WahooFitness/wahoo-offline-mfe";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  ListItem,
  ListItemButton,
  ListItemText,
  Switch,
  Typography,
} from "@mui/material";
import { CheckMarkIcon, ChevronDownIcon, CloseIcon, InfoIcon } from "@WahooFitness/redesignr";
import useThirdPartySharing, {
  availableCapabilities,
  ConnectedAppListItem,
} from "./useThirdPartySharing";
import { Trans, t } from "@lingui/macro";
import useFlaggedFeatures from "@/hooks/useFlaggedFeatures";
import { FlaggedFeature } from "@/hooks/types/FlaggedFeature";

const getLocalizedCapabilityMapping = (): Record<string, string> => ({
  import_plans: t`Import plans`,
  import_routes: t`Import routes`,
  import_summaries: t`Import summaries`,
  share_summaries: t`Share summaries`,
  import_segments: t`Import segments`,
});

const AuthorizedApp: React.FC<ConnectedAppListItem> = ({
  icon,
  content,
  authorized,
  supportedCapabilities,
  handleDeauth,
  nativeHealth,
}) => {
  const { setDialog, handleClose } = useDialogContext();
  const capabilityMapping = getLocalizedCapabilityMapping();
  const { checkIsFeatureEnabled } = useFlaggedFeatures();

  const handleConfirmDeauth = useCallback(async () => {
    await handleDeauth();
    handleClose();
  }, [handleClose, handleDeauth]);

  const handleOpenDialog = useCallback(() => {
    setDialog({
      open: true,
      title: t`Deauthorize ${content}?`,
      body: t`Are you sure? Deauthorizing will stop sharing across all Wahoo products.`,
      actions: [
        { text: t`Cancel` },
        { text: t`Deauthorize`, color: "error", action: handleConfirmDeauth },
      ],
    });
  }, [handleConfirmDeauth, setDialog, content]);

  const capabilityOrder = useMemo(() => {
    return availableCapabilities.reduce(
      (acc, curr, i) => {
        acc[curr] = i;
        return acc;
      },
      {} as Record<string, number>
    );
  }, []);

  const sortCapabilities = useCallback(
    ([a, aEnabled]: [string, boolean], [b, bEnabled]: [string, boolean]) => {
      if (aEnabled === bEnabled) {
        return (capabilityOrder[a] ?? Infinity) - (capabilityOrder[b] ?? Infinity);
      }
      return aEnabled ? -1 : 1;
    },
    [capabilityOrder]
  );

  const { updateAutoShareSummaries } = useThirdPartySharing();

  const [disableAutoShareToggle, setDisableAutoShareToggle] = useState(!!nativeHealth);

  const handleAutoShareToggle = useCallback(
    async (_: any, checked: boolean) => {
      if (!authorized?.id) return;
      setDisableAutoShareToggle(true);
      await updateAutoShareSummaries(authorized.id, checked);
      setDisableAutoShareToggle(false);
    },
    [authorized, updateAutoShareSummaries]
  );

  const secondaryText = useMemo(() => {
    if (authorized?.share_summaries) {
      if (nativeHealth) {
        return t`Automatically share activity after each workout in the app. Deauthorize to disable.`;
      }
      return t`Automatically share activity after each workout. This applies to any Wahoo workout from ELEMNT or the app.`;
    }
    return t`Auto-share is only available with Share Summaries, please deauthorize and reauthorize with Share Summaries to enable auto-share.`;
  }, [authorized?.share_summaries, nativeHealth]);

  const openInfoDialog = useCallback(() => {
    setDialog({
      open: true,
      title: t`Function disabled`,
      body: t`To enable this functionality, please re-authorize this app`,
      actions: [{ text: t`Got it` }],
    });
  }, [setDialog]);

  if (!authorized) {
    // Shouldn't happen, but typescript.
    return null;
  }
  const { id: _id, created_at: _created_at, updated_at: _updated_at, ...capabilities } = authorized;

  return (
    <Accordion
      elevation={4}
      disableGutters
      sx={{ "&:last-of-type": { borderRadius: 2 }, "&:first-of-type": { borderRadius: 2 } }}
    >
      <AccordionSummary expandIcon={<ChevronDownIcon />}>
        <Box display="flex" justifyContent="space-between" alignItems="center" gap={1}>
          <Box display="flex" gap={1}>
            {icon}
          </Box>
          <Box display="flex" flexDirection="column">
            <Typography variant="prose-base">{content}</Typography>
            <Typography variant="prose-sm" color="success.main">
              <Trans>Authorized</Trans>
            </Typography>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ px: 0, pb: 0 }}>
        <Box>
          {checkIsFeatureEnabled(FlaggedFeature.AutoShareSummary) && (
            <>
              <Divider />
              <ListItem dense>
                <ListItemText
                  primary={t`Auto-share`}
                  primaryTypographyProps={{ variant: "prose-base" }}
                  secondary={secondaryText}
                />
                <Switch
                  checked={capabilities.auto_share_summaries}
                  onChange={handleAutoShareToggle}
                  color="info"
                  disabled={disableAutoShareToggle || !authorized.share_summaries}
                />
              </ListItem>
            </>
          )}
          <Divider />
          {Object.entries(capabilities)
            .filter(([cap]) => supportedCapabilities.includes(cap as keyof typeof capabilities))
            .sort(sortCapabilities)
            .map(([cap, value]) => {
              if (capabilityMapping[cap]) {
                return (
                  <React.Fragment key={cap}>
                    <ListItem dense>
                      <ListItemText>
                        <Box display="flex" alignItems="center" gap={0.5}>
                          <Typography
                            variant="prose-sm"
                            color={value ? undefined : "text.disabled"}
                          >
                            {capabilityMapping[cap]}
                          </Typography>
                          {!value && <InfoIcon color="disabled" onClick={openInfoDialog} />}
                        </Box>
                      </ListItemText>
                      {value ? <CheckMarkIcon /> : <CloseIcon color="disabled" />}
                    </ListItem>
                    <Divider />
                  </React.Fragment>
                );
              }
            })}
          <ListItem onClick={handleOpenDialog} disablePadding>
            <ListItemButton sx={{ height: "46px" }}>
              <Typography variant="prose-base" color="error">
                <Trans>Deauthorize</Trans>
              </Typography>
            </ListItemButton>
          </ListItem>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default AuthorizedApp;
