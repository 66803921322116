import { ChartRadial } from "@carbon/icons-react";
import { Trans } from "@lingui/macro";
import { alpha, Box, Typography, useTheme } from "@mui/material";

function HowWeCalculateDrawer() {
  const { palette } = useTheme();
  return (
    <Box display="flex" flexDirection="column" alignItems="center" p={2} pb={4} gap={3}>
      <Box
        p={2}
        borderRadius="50%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        bgcolor={alpha(palette.info.main, 0.3)}
      >
        <ChartRadial size={32} />
      </Box>

      <Typography variant="prose-base">
        <Trans>
          We are looking at athlete parameters - height, weight, age, and fitness level (based on
          FTP), and spitting out a number for each metric on scale of -1 to 1, where 0 is average.
        </Trans>
      </Typography>
    </Box>
  );
}

export default HowWeCalculateDrawer;
