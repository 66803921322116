import { WahooWorkoutTypeFamily } from "@WahooFitness/cloud-client-types";
import { MenuListItemType, MenuListItemVariant } from "@WahooFitness/redesignr";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getWorkoutTypeNames } from "../WorkoutProfiles/AddWorkoutProfile/WorkoutTypeNames";
import { Checkmark } from "@carbon/icons-react";
import { useTheme } from "@mui/material";
import { useCloudContext, useConfigContext, useOfflineSWR } from "@WahooFitness/wahoo-offline-mfe";
import { UserProfiles } from "@WahooFitness/cloud-client-ts";

const sportOptionsList = [
  WahooWorkoutTypeFamily.Cycling,
  WahooWorkoutTypeFamily.Running,
  WahooWorkoutTypeFamily.Multisport,
  WahooWorkoutTypeFamily.Other,
];

const useTrainingPreferences = (initializeProfile = true) => {
  const { wahooToken } = useConfigContext();
  const { getCloudClient } = useCloudContext();
  const { palette } = useTheme();
  const workoutTypeNames = getWorkoutTypeNames();
  const [selectedSportOptions, setSelectedSportOptions] = useState<Set<WahooWorkoutTypeFamily>>(
    new Set()
  );

  const userProfilesClient = useMemo(() => getCloudClient(UserProfiles), [getCloudClient]);
  const { data: userProfilesData } = useOfflineSWR(
    ["trainingPreferences", wahooToken],
    ([_key, token]) => userProfilesClient.get(token)
  );

  useEffect(() => {
    if (userProfilesData?.workout_type_family_ids && initializeProfile) {
      setSelectedSportOptions(new Set(userProfilesData.workout_type_family_ids));
    }
  }, [initializeProfile, userProfilesData?.workout_type_family_ids]);

  const setTrainingPreferences = useCallback(
    (newSelectedSportOptions: Set<WahooWorkoutTypeFamily>) => {
      if (userProfilesData?.id) {
        userProfilesClient.upsert(
          wahooToken,
          userProfilesData.id,
          Array.from(newSelectedSportOptions)
        );
      }
    },
    [userProfilesClient, wahooToken, userProfilesData?.id]
  );

  const selectSport = useCallback(
    (sportType: WahooWorkoutTypeFamily) => {
      const newSelectedSportOptions = new Set(selectedSportOptions);
      if (newSelectedSportOptions.has(sportType)) {
        newSelectedSportOptions.delete(sportType);
      } else {
        newSelectedSportOptions.add(sportType);
      }
      setSelectedSportOptions(newSelectedSportOptions);
      setTrainingPreferences(newSelectedSportOptions);
    },
    [selectedSportOptions, setTrainingPreferences]
  );

  const sportOptionsNameList: { name: string; value: WahooWorkoutTypeFamily }[] = useMemo(
    () =>
      Object.entries(WahooWorkoutTypeFamily)
        .filter(([_name, value]) => sportOptionsList.includes(value as WahooWorkoutTypeFamily))
        .map(([name, value]) => ({ name, value: value as WahooWorkoutTypeFamily })),
    []
  );

  const sportOptionListItems: MenuListItemType[] = useMemo(
    () =>
      sportOptionsNameList.map(({ name: sportName, value: sportValue }) => {
        return {
          id: String(sportValue),
          variant: MenuListItemVariant.Action,
          content: workoutTypeNames[sportName],
          action: () => selectSport(sportValue),
          actionComponent: selectedSportOptions.has(sportValue) ? (
            <Checkmark size={24} color={palette.info.main} />
          ) : null,
        };
      }),
    [palette.info.main, selectSport, selectedSportOptions, sportOptionsNameList, workoutTypeNames]
  );

  return {
    selectedSportOptions,
    setSelectedSportOptions,
    setTrainingPreferences,
    sportOptionListItems,
  };
};

export default useTrainingPreferences;
