import { Dispatch, SetStateAction } from "react";
import { Box, FormControlLabel, Checkbox, FormControl } from "@mui/material";
import useSingleSelect from "./useSingleSelect";
import { Responses } from "../useFourDPQuiz";
import {
  RedesignrRadioGroup,
  RedesignrFormControlLabel,
  RedesignrRadio,
  RedesignrFormControlLabelContent,
} from "@WahooFitness/redesignr";
import { AnswerListPossibility } from "@WahooFitness/cloud-client-types/FourDPQuizType";

interface Props {
  initialVal?: string;
  options: Array<AnswerListPossibility>;
  skipText?: string;
  setCurrentStepHasValue: (val: boolean) => void;
  setResponses: Dispatch<SetStateAction<Responses | undefined>>;
  questionId: number;
  center?: boolean;
}
function SingleSelect({
  initialVal,
  setCurrentStepHasValue,
  setResponses,
  questionId,
  skipText,
  options,
  center,
}: Props) {
  const { val, onChange, skipChecked, handleSkipToggle } = useSingleSelect({
    initialVal,
    setCurrentStepHasValue,
    setResponses,
    questionId,
  });
  return (
    <Box display="flex" flexDirection="column" width="100%" gap={2}>
      <FormControl sx={{ width: "100%" }} disabled={skipChecked}>
        <RedesignrRadioGroup
          name="single-select-radio-button-group"
          onChange={onChange}
          value={val}
        >
          {options.map((option) => (
            <RedesignrFormControlLabel
              key={option.key}
              value={option.key}
              control={<RedesignrRadio />}
              label={
                <RedesignrFormControlLabelContent
                  align={center ? "center" : "left"}
                  label={option.text.title}
                  secondary={option.text.subtext}
                />
              }
            />
          ))}
        </RedesignrRadioGroup>
      </FormControl>

      {skipText && (
        <Box mx={2}>
          <FormControlLabel
            control={<Checkbox checked={skipChecked} onChange={handleSkipToggle} />}
            label={skipText}
          />
        </Box>
      )}
    </Box>
  );
}

export default SingleSelect;
