import { useState, useCallback, ChangeEvent, Dispatch, SetStateAction } from "react";
import { Responses } from "../useFourDPQuiz";

interface Props {
  initialVal?: string;
  setCurrentStepHasValue: (val: boolean) => void;
  setResponses: Dispatch<SetStateAction<Responses | undefined>>;
  questionId: number;
}

function useSingleSelect({ initialVal, setCurrentStepHasValue, setResponses, questionId }: Props) {
  const [val, setVal] = useState(initialVal === "skip" ? undefined : initialVal);
  const [skipChecked, setSkipChecked] = useState(initialVal === "skip");

  const onChange = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      const newVal = ev.target.value;
      setVal(newVal);
      if (newVal) {
        setCurrentStepHasValue(true);
      } else {
        setCurrentStepHasValue(false);
      }
      setResponses((prev: any) => {
        prev[questionId] = [newVal];
        return prev;
      });
    },
    [questionId, setCurrentStepHasValue, setResponses]
  );

  const handleSkipToggle = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      if (ev.target.checked) {
        setSkipChecked(true);
        setCurrentStepHasValue(true);
        setResponses((prev: any) => {
          prev[questionId] = ["skip"];
          return prev;
        });
      } else {
        setSkipChecked(false);
        setCurrentStepHasValue(!!val);
        setResponses((prev: any) => {
          prev[questionId] = [val];
          return prev;
        });
      }
    },
    [questionId, setCurrentStepHasValue, setResponses, val]
  );

  return {
    val,
    onChange,
    skipChecked,
    handleSkipToggle,
  };
}

export default useSingleSelect;
