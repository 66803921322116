import { WSMRoute } from "@WahooFitness/wsm-native/dist/esm/types/route";

const mockRoutes: WSMRoute[] = [
  {
    "#dbId": 1,
    id: 1,
    user_id: 1,
    route_provider_type: 12,
    provider_updated_at: "2022-01-27T16:18:53.927Z",
    provider_id: "r0001",
    name: "curated route test 1",
    start_lat: 33.975087,
    start_lng: -85.105208,
    distance: 32180,
    ascent: 100,
    descent: 70,
    workout_type_family_id: 0,
    pinned: false,
    fitness_app_id: 1154,
    file: {
      url: "https://cdn.wahooligan.com/wahoo-cloud/production/uploads/route/file/bBtRC6vjwTXAzvrvUh21hg/gps_48290980_route.fit",
    },
    deleted: false,
    expires_at: "2016-02-27T16:18:53.927Z",
    created_at: "2016-01-25T16:18:53.927Z",
    updated_at: "2016-01-27T16:18:53.927Z",
    token: "route1",
  },
  {
    "#dbId": 2,
    id: 2,
    user_id: 1,
    route_provider_type: 12,
    provider_updated_at: "2022-01-27T16:18:53.927Z",
    provider_id: "r0002",
    name: "curated test 2",
    start_lat: 41.975087,
    start_lng: -75.105208,
    distance: 190308,
    ascent: 3.5,
    descent: 3.5,
    workout_type_family_id: 0,
    pinned: false,
    fitness_app_id: 1411,
    file: {
      url: "https://cdn.wahooligan.com/wahoo-cloud/production/uploads/route/file/nxEm1dLCslOtd0YmQTPAIg/20240710_131940_route.fit",
    },
    deleted: false,
    expires_at: "2016-02-27T16:18:53.927Z",
    created_at: "2016-01-26T16:18:53.927Z",
    updated_at: "2016-01-27T16:18:53.927Z",
    token: "route2",
  },
  {
    "#dbId": 3,
    id: 3,
    user_id: 1,
    route_provider_type: 12,
    provider_updated_at: "2022-01-27T16:18:53.927Z",
    provider_id: "rxjs3",
    name: "Wahoo curated route test 3",
    start_lat: 33.45579,
    start_lng: -84.22397,
    distance: 1234,
    ascent: 321,
    descent: 36,
    workout_type_family_id: 1,
    pinned: false,
    fitness_app_id: 1131,
    file: {
      url: "https://cdn.wahooligan.com/wahoo-cloud/production/uploads/route/file/bBtRC6vjwTXAzvrvUh21hg/gps_48290980_route.fit",
    },
    deleted: false,
    expires_at: "2016-02-27T16:18:53.927Z",
    created_at: "2016-01-27T16:18:53.927Z",
    updated_at: "2016-01-27T16:18:53.927Z",
    token: "route3",
  },
  {
    "#dbId": 4,
    id: 4,
    user_id: 1,
    route_provider_type: 12,
    provider_updated_at: "2022-01-27T16:18:53.927Z",
    provider_id: "rxjs4",
    name: "curated wahoo route test 4",
    start_lat: 33.45579,
    start_lng: -84.22397,
    distance: 16090,
    ascent: 47,
    descent: 36,
    workout_type_family_id: 1,
    pinned: false,
    fitness_app_id: 1131,
    file: {
      url: "https://cdn.wahooligan.com/wahoo-cloud/production/uploads/route/file/nxEm1dLCslOtd0YmQTPAIg/20240710_131940_route.fit",
    },
    deleted: false,
    expires_at: "2016-02-27T16:18:53.927Z",
    created_at: "2016-01-28T16:18:53.927Z",
    updated_at: "2016-01-27T16:18:53.927Z",
    token: "route4",
  },
  {
    "#dbId": 5,
    id: 5,
    user_id: 1,
    route_provider_type: 12,
    provider_updated_at: "2022-01-27T16:18:53.927Z",
    provider_id: "rxjs5",
    name: "Curata de Wahoo de routos",
    start_lat: 33.45579,
    start_lng: -84.22397,
    distance: 45123,
    ascent: 1065,
    descent: 1017,
    workout_type_family_id: 1,
    pinned: false,
    fitness_app_id: 1086,
    file: {
      url: "https://cdn.wahooligan.com/wahoo-cloud/production/uploads/route/file/bBtRC6vjwTXAzvrvUh21hg/gps_48290980_route.fit",
    },
    deleted: false,
    expires_at: "2016-02-27T16:18:53.927Z",
    created_at: "2016-01-28T16:18:53.927Z",
    updated_at: "2016-01-27T16:18:53.927Z",
    token: "route5",
  },
  {
    "#dbId": 6,
    id: 6,
    user_id: 1,
    route_provider_type: 12,
    provider_updated_at: "2022-01-27T16:18:53.927Z",
    provider_id: "rxjs6",
    name: "Routen von Wahoo das gecuraten",
    start_lat: 33.45579,
    start_lng: -84.22397,
    distance: 80012,
    ascent: 111,
    descent: 104,
    workout_type_family_id: 1,
    pinned: false,
    fitness_app_id: 1087,
    file: {
      url: "https://cdn.wahooligan.com/wahoo-cloud/production/uploads/route/file/nxEm1dLCslOtd0YmQTPAIg/20240710_131940_route.fit",
    },
    deleted: false,
    expires_at: "2016-02-27T16:18:53.927Z",
    created_at: "2016-01-28T16:18:53.927Z",
    updated_at: "2016-01-27T16:18:53.927Z",
    token: "route6",
  },
  {
    "#dbId": 7,
    id: 7,
    user_id: 1,
    route_provider_type: 12,
    provider_updated_at: "2022-01-27T16:18:53.927Z",
    provider_id: "rxjs7",
    name: "Omlette du fromWahoo",
    start_lat: 33.45579,
    start_lng: -84.22397,
    distance: 17012,
    ascent: 1211,
    descent: 1104,
    pinned: false,
    fitness_app_id: 1087,
    file: {
      url: "https://cdn.wahooligan.com/wahoo-cloud/production/uploads/route/file/nxEm1dLCslOtd0YmQTPAIg/20240710_131940_route.fit",
    },
    deleted: false,
    expires_at: "2016-02-27T16:18:53.927Z",
    created_at: "2012-01-28T16:18:53.927Z",
    updated_at: "2016-01-27T16:18:53.927Z",
    token: "route7",
  },
  {
    "#dbId": 8,
    id: 8,
    user_id: 1,
    route_provider_type: 12,
    provider_updated_at: "2022-01-27T16:18:53.927Z",
    provider_id: "rxjs8",
    name: "Coorated",
    start_lat: -33.45579,
    start_lng: 84.22397,
    distance: 212,
    ascent: 1,
    descent: 1,
    workout_type_family_id: 2,
    pinned: false,
    fitness_app_id: 1087,
    file: {
      url: "https://cdn.wahooligan.com/wahoo-cloud/production/uploads/route/file/nxEm1dLCslOtd0YmQTPAIg/20240710_131940_route.fit",
    },
    deleted: false,
    expires_at: "2016-02-27T16:18:53.927Z",
    created_at: "2022-01-19T16:17:53.927Z",
    updated_at: "2016-01-27T16:18:53.927Z",
    token: "route8",
  },
];

export default mockRoutes;
