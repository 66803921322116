import { useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import WarmingUp from "@/components/WarmUp";
import { useInView } from "react-intersection-observer";
import { t, Trans } from "@lingui/macro";
import SearchBar from "@/components/SearchBar";
import FilterBar from "@/components/FilterBar/FilterBar";
import { PullToRefresh } from "@WahooFitness/wahoo-offline-mfe";
import RouteGroup from "./RouteGroup";
import { defaultRouteFilterParams, FilterParams } from "@/components/FilterBar/FilterParams";
import SelectRouteDrawer from "../SelectRouteDrawer";
import { SortDirectionOption, SortOption, SortParams } from "@/components/FilterBar/SortParams";
import { ExtendedWSMRoute } from "@/contexts/RoutesContext";

const RouteList = ({
  refreshRouteList,
  filterParams,
  updateFilter,
  sortParams,
  sortDirections,
  sortOptions,
  setSortDirection,
  setSortKey,
  filtersApplied,
  publicRouteList,
  clearAllFilters,
  mainRouteList,
  selectRouteDrawerOpen,
  closeSelectRouteDrawer,
  shouldHideSourceIcons = false,
}: {
  refreshRouteList: () => void;
  filterParams: FilterParams;
  updateFilter: (value: FilterParams) => void;
  sortParams: SortParams;
  sortDirections: SortDirectionOption[];
  sortOptions: SortOption[];
  setSortDirection: (direction: string) => void;
  setSortKey: (key: string) => void;
  filtersApplied: boolean;
  publicRouteList?: ExtendedWSMRoute[];
  clearAllFilters: () => void;
  mainRouteList: ExtendedWSMRoute[];
  selectRouteDrawerOpen: boolean;
  closeSelectRouteDrawer: () => void;
  shouldHideSourceIcons?: boolean;
}) => {
  const { palette } = useTheme();
  const [visibleCount, setVisibleCount] = useState(7);

  const { ref: loadMoreRef } = useInView({
    onChange: (inView: boolean) => {
      if (inView) {
        setTimeout(() => {
          setVisibleCount((prevCount) => prevCount + 3);
        }, 400);
      }
    },
    rootMargin: "0px 0px 400px 0px",
  });

  return (
    <Box display="flex" flexDirection="column" pt={2}>
      <PullToRefresh onRefresh={refreshRouteList} />
      <SearchBar searchTerm={filterParams?.search} updateFilter={updateFilter} />
      <FilterBar
        sortParams={sortParams}
        sortDirections={sortDirections}
        sortOptions={sortOptions}
        setSortDirection={setSortDirection}
        setSortKey={setSortKey}
        filterParams={filterParams}
        updateFilter={updateFilter}
        defaultFilterParams={defaultRouteFilterParams}
      />
      {!filtersApplied && publicRouteList && publicRouteList.length > 0 && (
        <>
          <Box mx={2} my={1}>
            <RouteGroup routes={publicRouteList} title={t`Shared as public`} />
          </Box>
        </>
      )}
      <Box display="flex" mx={4} my={1} alignItems="center" justifyContent="space-between">
        {filtersApplied && (
          <Box aria-role="button" sx={{ cursor: "pointer" }} onClick={clearAllFilters}>
            <Typography variant="prose-xs-medium" color={palette.info.main}>
              <Trans>Reset filters</Trans>
            </Typography>
          </Box>
        )}
      </Box>
      <Box display="flex" flexDirection="column" rowGap={1} alignItems="center" mx={2}>
        {mainRouteList.length === 0 && (
          <Typography>
            <Trans>No routes matching your current filter selection.</Trans>
          </Typography>
        )}
        <RouteGroup
          routes={mainRouteList.slice(0, visibleCount)}
          title={t`${mainRouteList.length} routes`}
          shouldHideSourceIcons={shouldHideSourceIcons}
        />
        {visibleCount < mainRouteList.length ? (
          <Box display="flex" justifyContent="center" ref={loadMoreRef} my={0.5}>
            <WarmingUp noTopSpacing />
          </Box>
        ) : (
          mainRouteList.length > 0 && (
            <Box my={1} mx={6}>
              <Typography align="center">
                <Trans>That&apos;s all your results for the current filter selection!</Trans>
              </Typography>
            </Box>
          )
        )}
      </Box>
      <SelectRouteDrawer open={selectRouteDrawerOpen} handleClose={closeSelectRouteDrawer} />
    </Box>
  );
};

export default RouteList;
