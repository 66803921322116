/**
 * This component is used to wrap a standard React Router 6 Outlet in a frame-motion animation.
 */

import { AnimatePresence, motion } from "motion/react";
import { cloneElement, useState } from "react";
import { useLocation, useOutlet, useBlocker } from "react-router";

const variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? "100vw" : "-100vw",
      zIndex: 0,
      opacity: 0,
    };
  },
  center: {
    x: 0,
    zIndex: 1,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      x: direction > 0 ? "-100vw" : "100vw",
      zIndex: 0,
      opacity: 0,
    };
  },
};

const AnimatedOutlet = (): React.JSX.Element => {
  const location = useLocation();
  const element = useOutlet();
  const [direction, setDirection] = useState(1);
  useBlocker(({ historyAction }) => {
    setDirection(historyAction === "POP" ? -1 : 1);
    return false;
  });
  return (
    <AnimatePresence mode="popLayout" initial={false} custom={direction}>
      <motion.div
        initial="enter"
        animate="center"
        exit="exit"
        variants={variants}
        custom={direction}
        transition={{ duration: 0.2 }}
        key={location.pathname}
        style={{ height: "100%" }}
      >
        {element && cloneElement(element, { key: location.pathname })}
      </motion.div>
    </AnimatePresence>
  );
};

export { AnimatedOutlet };
